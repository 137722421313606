import { FC } from 'react';
import { Box, SxProps, useTheme } from '@mui/material';

type WelcomeIllustrationProps = {
  sx?: SxProps;
};

const WelcomeIllustration: FC<WelcomeIllustrationProps> = ({ sx }) => {
  const theme = useTheme();
  const COLOR_PRIMARY = theme.palette.primary.main;

  return (
    <Box sx={sx}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 660.04004 477.55556"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path
          d="M660.04004,262.9298h0c0,.46393-.3761,.84003-.84003,.84003H0v-1.68005H659.20001c.46393,0,.84003,.3761,.84003,.84003Z"
          fill="#e6e6e6"
        />
        <g>
          <circle cx="216.7484" cy="34.0197" r="24.91179" fill="#ffb6b6" />
          <path
            d="M229.94928,64.18796l-32.79055,9.36873-4.68436,10.53982-10.53982,50.35692v79.6342s-12.882,28.10619-5.85546,52.69911c7.51071,26.28749,33.96165-4.73969,53.8702-1.19875,19.90855,3.54094,51.52801,7.05421,51.52801,7.05421l-18.73746-85.48966-4.68436-104.22712-28.10619-18.73746Z"
            fill="#e6e6e6"
          />
          <path
            d="M223.76134,42.17768l-5.06481-2.5324s-1.2662-11.39582-3.79861-13.92822-2.5324-3.79861-2.5324-3.79861l3.79861-5.06481s-18.99303,10.12962-22.79164,3.79861,1.2662-8.86341,1.2662-8.86341l-6.33101-5.06481s7.59721,5.06481,8.86341,1.2662,8.86341-6.37588,8.86341-6.37588c0,0,16.59024,3.75374,15.25923,0s3.7338,.04487,5,1.31107,12.66202,13.92822,13.92822,12.66202,7.59721,2.5324,6.33101,3.79861-1.2662,2.5324,0,3.79861,8.86341,15.19443,2.5324,20.25923-15.19443,11.39582-13.92822,16.46063,0-16.46063-11.39582-17.72683Z"
            fill="#2f2e41"
          />
          <g>
            <path
              id="uuid-9ec032c4-1053-42c3-862e-530ead60710b-259"
              d="M100.95799,104.89896c-6.39397-5.51185-8.73284-13.27878-5.2244-17.34737,3.50845-4.06859,11.53448-2.89783,17.93,2.61684,2.58496,2.16784,4.61318,4.92321,5.91495,8.03559l26.79659,23.69607-11.3879,12.33783-25.20765-24.67002c-3.27122-.82947-6.29616-2.43045-8.82158-4.66895Z"
              fill="#ffb6b6"
            />
            <path
              d="M192.75544,84.49934l17.65114-1.60465,15.47964,73.82595c2.9496,14.06732-6.27162,27.80125-20.40865,30.39628l-12.72213,2.33531L111.66907,118.05816l11.84671-19.74888,59.79747,37.51023,9.4422-51.32018Z"
              fill="#e6e6e6"
            />
          </g>
          <g>
            <path d="M153.34876,412.8125s-14,26-13,25,18,5,18,5l10-26-15-4Z" fill="#ffb6b6" />
            <path
              d="M132.68509,230.81684s-7.16655,28.64683-6.44358,27.43137,18.67696,.41363,18.67696,.41363l3.28972-27.66185-15.52309-.18316Z"
              fill="#ffb6b6"
            />
            <path
              d="M257.34876,226c-31.63831,4.44458-60.21521,.56946-83.5-17.6875,0,0-17,57,14,63s65.5-4.3125,69.5-14.3125,0-31,0-31Z"
              fill="#2f2e41"
            />
            <polygon
              points="198.34876 244 163.84876 325.3125 147.84876 419.3125 168.84876 424.3125 206.41175 337.8125 257.34876 255 198.34876 244"
              fill="#2f2e41"
            />
            <path
              d="M193.84876,220.8125s-8-62-23-66c-7.31258-1.95002-14.14983-1.76109-19.23729-1.05526-5.12763,.7114-9.2153,4.62901-10.11188,9.72753l-13.63114,77.51524h19.27559l6.20472-21,16.5,28.8125,24-28Z"
              fill="#2f2e41"
            />
            <path
              d="M144.84876,250.8125l-19.05906-2-2.94094,11s-31,28-15,30c16,2,31-9,31-9l22.00984-12.62598s-8.50984-22.68652-14.50984-22.68652-1.5,5.3125-1.5,5.3125Z"
              fill="#2f2e41"
            />
            <path
              d="M154.84876,438.3125l-19.05906-2-2.94094,11s-31,28-15,30,31-9,31-9l22.00984-12.62598s-1.50984-23.68652-7.50984-23.68652c-6,0-8.5,6.3125-8.5,6.3125Z"
              fill="#2f2e41"
            />
          </g>
          <g>
            <path
              id="uuid-0c2c35cf-ee7e-4b84-8f52-274d32364181-260"
              d="M251.35106,286.92829c-2.298,7.88141-8.22731,13.08423-13.24285,11.62094-5.01554-1.46328-7.21728-9.03767-4.91741-16.92158,.87551-3.1619,2.49549-6.06816,4.72445-8.47561l10.15843-33.27088,15.51144,5.09985-11.66124,32.25775c.58564,3.22925,.38922,6.55181-.57282,9.68953Z"
              fill="#ffb6b6"
            />
            <path
              d="M262.08753,83.07027l-8.08811-2.28114v.00002c-7.62668,15.17437-10.90619,32.16302-9.47558,49.08582l3.99188,47.2203-13.17149,82.99774,25.81615,6.67893,20.34392-104.15843-19.41677-79.54323Z"
              fill="#e6e6e6"
            />
          </g>
        </g>
        <g>
          <path
            d="M104.62748,212.83563c-.35419-1.08385-3.03868-.60669-3.29642-1.57612-.25685-.96594,2.31912-1.77589,4.29475-4.49802,.3563-.49089,2.60143-3.5844,1.74035-4.88552-1.63216-2.46625-12.98817,3.67622-15.71401,.22927-.59824-.75646-.84437-2.05513-2.62649-4.47199-.70898-.96147-1.16382-1.42564-1.70728-1.4119-.77113,.01948-1.04704,.98418-2.25903,2.73166-1.81761,2.6207-2.47353,2.29167-3.67091,4.43894-.88887,1.59402-.9942,2.61253-1.63519,2.67256-.90705,.08497-1.28185-1.89965-2.3902-1.97665-1.13101-.07854-2.26276,1.88246-2.8238,3.41457-1.05285,2.8751-.40089,5.07265-.25634,7.26272,.15706,2.38005-.21174,5.86405-2.79775,10.33581l-24.39164,38.86187c5.23835-7.92231,20.10799-29.52575,26.09893-37.44997,1.72948-2.28755,3.58802-4.58456,6.44861-5.0167,2.75409-.41608,6.10513,.9465,10.78244,.86718,.54625-.00924,2.06647-.0544,2.43625-.86016,.30573-.66614-.45336-1.24533-.17722-1.8715,.37038-.83991,2.03679-.47957,4.30777-1.03737,1.60136-.39336,2.6893-1.04324,3.62486-1.60214,.28206-.1685,4.48389-2.71375,4.01237-4.15656h-.00004Z"
            fill="#f2f2f2"
          />
          <path
            d="M40.74128,221.90898c-.63416-.1389-1.17451,1.31635-1.72468,1.16314-.54821-.15264-.20248-1.65064-.96847-3.40572-.13813-.3165-1.00865-2.31103-1.89572-2.2638-1.68137,.08948-1.94319,7.43538-4.42147,7.77869-.54387,.07536-1.25231-.17855-2.9527-.00121-.67646,.07056-1.03551,.15987-1.18558,.43056-.21293,.38409,.18092,.79788,.68891,1.8969,.76182,1.64824,.41106,1.87525,1.11947,3.08248,.52589,.89619,.99539,1.24177,.8399,1.57368-.22,.4697-1.30221,.08104-1.65979,.60279-.36488,.53245,.27105,1.65372,.86113,2.37114,1.10735,1.34631,2.37402,1.66038,3.49063,2.22135,1.21346,.60963,2.81703,1.7959,4.26564,4.35538l12.03589,23.18446c-2.3769-4.85686-8.68964-18.38826-10.85031-23.61505-.62375-1.50886-1.21488-3.08381-.60158-4.61251,.59043-1.47178,2.22607-2.72333,3.5367-5.04187,.15308-.27078,.56954-1.02994,.28078-1.44392-.23873-.34225-.74203-.13679-.96968-.453-.30536-.42414,.35231-1.13805,.73376-2.4136,.26898-.89945,.26391-1.62093,.25955-2.24138-.00132-.18705-.03819-2.98371-.88236-3.1686v.00006Z"
            fill="#f2f2f2"
          />
          <path
            d="M52.7489,227.56582l.37832-3.49289,.23204-.21944c1.07353-1.01459,1.74511-2.08947,1.99703-3.19576,.04009-.17587,.07113-.35379,.1029-.53496,.12563-.71817,.28159-1.61095,.97206-2.53825,.38642-.51664,1.40861-1.68432,2.46285-1.27646,.28454,.10665,.47659,.29111,.61001,.48669,.03349-.03397,.06781-.06778,.1045-.10451,.45539-.44254,.77396-.60465,1.08083-.76077,.2358-.12014,.47929-.24373,.89134-.5733,.18077-.1445,.32552-.27458,.44749-.38383,.36992-.33031,.84308-.71708,1.4912-.56388,.68704,.18779,.9226,.84315,1.07888,1.27702,.27909,.77389,.36506,1.30609,.42193,1.65905,.02048,.12894,.04423,.27414,.0622,.32134,.15038,.379,2.03808,.47702,2.74835,.51798,1.59658,.08663,2.97812,.1616,3.31036,1.25481,.2384,.78209-.25846,1.63511-1.51932,2.60275-.39275,.30105-.78541,.50883-1.12749,.664,.21041,.19894,.37804,.4695,.3883,.83799h0c.0231,.87225-.87152,1.54156-2.65888,1.99276-.44416,.11339-1.04159,.26343-1.82338,.22552-.36818-.01821-.6907-.07459-.96567-.12728-.041,.15667-.11618,.31955-.25171,.47414-.39781,.45538-1.04027,.57117-1.91232,.31448-.96274-.26828-1.72342-.63312-2.39463-.95383-.5863-.27931-1.09182-.51816-1.516-.58341-.78894-.11033-1.52462,.37678-2.40503,1.02507l-2.20612,1.65499h-.00002Z"
            fill="#f2f2f2"
          />
          <path
            d="M59.04516,207.67108l-3.47462,.52002-.27111-.16879c-1.25371-.78108-2.46376-1.15805-3.59778-1.12111-.1803,.00584-.36025,.02093-.54357,.03616-.72655,.06063-1.6297,.13624-2.7018-.29644-.59778-.24273-1.98653-.93531-1.85941-2.05852,.03099-.30227,.16071-.53484,.31606-.7135-.04138-.02377-.08278-.04838-.1276-.07456-.54357-.32826-.7812-.59529-1.01005-.85253-.17602-.19762-.35734-.40179-.78064-.71678-.18563-.13821-.34817-.24522-.48479-.33548-.41335-.27401-.90747-.63364-.92367-1.29942,.00738-.7122,.58157-1.10629,.9616-1.36752,.6778-.46625,1.17077-.68442,1.49777-.82896,.11952-.0525,.25396-.11232,.29506-.14167,.32846-.24159-.05553-2.09241-.19606-2.78985-.32117-1.56633-.5991-2.9217,.37411-3.52036,.69605-.42897,1.6472-.16474,2.903,.80945,.39082,.30354,.69141,.63066,.92828,.9222,.13906-.25398,.35825-.48479,.7121-.58814h0c.83787-.24358,1.7122,.452,2.60201,2.06645,.22234,.40087,.51902,.94071,.68064,1.70654,.07577,.36075,.10303,.68702,.12182,.96639,.16196-.00009,.33856,.03133,.52248,.12321,.54138,.26929,.81637,.86137,.78926,1.77001-.0153,.9993-.17526,1.82765-.31524,2.55826-.12147,.63798-.22429,1.18755-.17979,1.6144,.0934,.79113,.75118,1.37918,1.60159,2.06637l2.16045,1.71418h-.00009Z"
            fill="#f2f2f2"
          />
          <path
            d="M59.51348,197.2368l.37832-3.49289,.23204-.21944c1.07353-1.01459,1.74511-2.08947,1.99703-3.19576,.04009-.17587,.07113-.35379,.1029-.53496,.12563-.71817,.28159-1.61095,.97206-2.53825,.38642-.51664,1.40861-1.68432,2.46285-1.27646,.28454,.10665,.47659,.29111,.61001,.48669,.03349-.03397,.06781-.06778,.1045-.10451,.45539-.44254,.77396-.60465,1.08083-.76077,.2358-.12014,.47929-.24373,.89134-.5733,.18077-.1445,.32552-.27458,.44749-.38383,.36992-.33031,.84308-.71708,1.4912-.56388,.68704,.18779,.9226,.84315,1.07888,1.27702,.27909,.77389,.36506,1.30609,.42193,1.65905,.02048,.12894,.04423,.27414,.0622,.32134,.15038,.379,2.03808,.47702,2.74835,.51798,1.59658,.08663,2.97812,.1616,3.31036,1.25481,.2384,.78209-.25846,1.63511-1.51932,2.60275-.39275,.30105-.78541,.50883-1.12749,.664,.21041,.19894,.37804,.4695,.3883,.83799h0c.0231,.87225-.87152,1.54156-2.65888,1.99276-.44416,.11339-1.04159,.26343-1.82338,.22552-.36818-.01821-.6907-.07459-.96567-.12728-.041,.15667-.11618,.31955-.25171,.47414-.39781,.45538-1.04027,.57117-1.91232,.31448-.96274-.26828-1.72342-.63312-2.39463-.95383-.5863-.27931-1.09182-.51816-1.516-.58341-.78894-.11033-1.52462,.37678-2.40503,1.02507l-2.20612,1.65499h-.00002Z"
            fill="#f2f2f2"
          />
          <path
            d="M48.24672,264.16175l-.61431-.60685,.01109-.8629-.01109,.8629-.85388-.09027c.00408-.08028-.00153-.26809-.00778-.56139-.03285-1.60682-.13482-6.49713,.54614-14.01104,.47549-5.24516,1.27005-10.56941,2.36162-15.82744,1.0934-5.26624,2.22818-9.18461,3.13963-12.33401,.68784-2.37536,1.38211-4.62838,2.05984-6.82293,1.80866-5.86329,3.51641-11.40187,4.57396-17.71941,.23682-1.41021,.72832-4.34583-.27798-7.81072-.58387-2.00954-1.58355-3.90108-2.97231-5.62031l1.34413-1.0855c1.53244,1.89982,2.63793,3.99462,3.28636,6.22585,1.11574,3.84165,.58022,7.04039,.32292,8.5773-1.07639,6.42907-2.80118,12.02087-4.62763,17.94063-.67427,2.18666-1.36631,4.42981-2.05113,6.79549-.90321,3.12018-2.02779,7.00451-3.10696,12.20312-1.07926,5.19512-1.86364,10.45429-2.33386,15.63327-.67194,7.4192-.571,12.23674-.53795,13.81941,.01708,.84502,.02077,1.02202-.2508,1.29483v-.00002Z"
            fill="#f2f2f2"
          />
          <path
            d="M52.51436,184.74532c-.06911-.01541-.13867-.03265-.20832-.05338-1.41759-.39736-2.55471-1.55264-3.38069-3.43309-.3866-.88425-.47786-1.81489-.65983-3.67089-.02857-.2862-.15473-1.73418,.00037-3.65318,.10091-1.25399,.23571-1.75764,.57696-2.15309,.37896-.44058,.88952-.69289,1.42905-.81956,.01459-.16603,.06891-.32837,.17666-.48226,.44384-.63856,1.18677-.37612,1.58988-.23958,.2031,.07294,.45619,.16566,.74088,.2136,.44739,.07734,.71454-.00128,1.11892-.11836,.38693-.11234,.86804-.25204,1.53301-.24018,1.31219,.01803,2.28353,.60778,2.60385,.80185,1.68691,1.01362,2.26934,2.63075,2.94336,4.5032,.13393,.37533,.57841,1.73194,.68191,3.47889,.07472,1.26033-.09043,1.77624-.2798,2.1451-.38679,.75839-.97906,1.10993-2.44902,1.84956-1.53571,.77504-2.30622,1.16285-2.93798,1.36221-1.47009,.4615-2.39322,.75133-3.47921,.50911v.00004Z"
            fill="#f2f2f2"
          />
        </g>
        <path
          d="M446.7331,38.54343s9.5367,5.59286,6.40901,11.12384-7.48797,13.06482-4.52991,15.43753c2.95806,2.37271,7.84181,12.83402,7.84181,12.83402,0,0,20.84546-2.91147,18.88847,13.28104-1.95699,16.19251-11.74981,18.4001-3.35622,25.52361,8.39359,7.12351-8.72353,15.64313-8.72353,15.64313,0,0-11.81694-3.68246-14.51291-15.00651s2.40329,8.65867,.6931,10.09147-8.3507,10.87704-13.04953-1.6796c-4.69882-12.55664-.04587-17.42509-6.25467-24.83589s-10.43002-19.39737-10.24509-21.49269-7.10211-21.21531,4.11419-28.67198c11.2163-7.45667,22.72528-12.24795,22.72528-12.24795Z"
          fill="#2f2e41"
        />
        <g>
          <path
            id="uuid-40eda929-ef4c-4923-ad7d-cda70967e632-261"
            d="M540.66959,214.23301c5.53698,4.58251,12.38962,5.44008,15.30538,1.91583,2.91576-3.52425,.79041-10.09475-4.74915-14.67816-2.18772-1.86124-4.78935-3.17149-7.58725-3.82113l-23.70562-19.12002-8.73535,11.3156,24.29696,17.6476c1.16196,2.62782,2.93649,4.93908,5.17504,6.74028Z"
            fill="#ffb6b6"
          />
          <path
            d="M469.62212,109.95774s-18.60329-12.44127-24.69534-6.34072c-6.09205,6.10055-4.63711,21.33801-4.63711,21.33801l48.78561,56.85605,38.68463,24.58959,10.37307-16.24274-32.5228-29.0209-35.98806-51.17928Z"
            fill="#3f3d56"
          />
        </g>
        <polygon
          points="442.3527 83.00787 449.1027 107 412.05545 107 424.2548 77.43806 442.3527 83.00787"
          fill="#ffb6b6"
        />
        <polygon
          points="442.3527 83.00787 449.1027 107 412.05545 107 424.2548 77.43806 442.3527 83.00787"
          opacity=".1"
        />
        <g>
          <path
            id="uuid-c7296f43-523d-4c33-91d5-0d02a2c7186e-262"
            d="M361.56011,260.14928c-.47825,7.17139,2.83434,13.23116,7.39831,13.53477s8.65017-5.2634,9.12763-12.43741c.23003-2.86311-.22893-5.73967-1.33852-8.38903l1.64263-30.41108-14.28746-.46665-.10246,30.02948c-1.45178,2.4795-2.28851,5.27069-2.44012,8.13992Z"
            fill="#ffb6b6"
          />
          <path
            d="M404.58148,141.53126s-.79011-22.36612-9.30155-23.73912-20.01953,8.71962-20.01953,8.71962l-17.58081,72.82555,2.68236,45.75973,19.24177-1.08692,4.51599-43.35379,20.46177-59.12508Z"
            fill="#3f3d56"
          />
        </g>
        <path d="M487.1027,408.8125s14,26,13,25-18,5-18,5l-10-26,15-4Z" fill="#ffb6b6" />
        <path
          d="M507.76636,226.81684s7.16655,28.64683,6.44358,27.43137-18.67696,.41363-18.67696,.41363l-3.28972-27.66185,15.52309-.18316Z"
          fill="#ffb6b6"
        />
        <path
          d="M452.1027,99h-34.95276l-38.04724,26-11,91,6,18,11,21s-11-46,16-28,29,21,29,21l36-18,13.5-74.5-10-41-17.5-15.5Z"
          fill="#3f3d56"
        />
        <path
          d="M383.1027,222c31.63831,4.44458,60.21521,.56946,83.5-17.6875,0,0,17,57-14,63-31,6-65.5-4.3125-69.5-14.3125-4-10,0-31,0-31Z"
          fill="#2f2e41"
        />
        <polygon
          points="442.1027 240 476.6027 321.3125 492.6027 415.3125 471.6027 420.3125 434.0397 333.8125 383.1027 251 442.1027 240"
          fill="#2f2e41"
        />
        <path
          d="M446.6027,216.8125s8-62,23-66c7.31258-1.95002,14.14983-1.76109,19.23729-1.05526,5.12763,.7114,9.2153,4.62901,10.11188,9.72753l13.63114,77.51524h-19.27559l-6.20472-21-16.5,28.8125-24-28Z"
          fill="#2f2e41"
        />
        <path
          d="M495.6027,246.8125l19.05906-2,2.94094,11s31,28,15,30c-16,2-31-9-31-9l-22.00984-12.62598s8.50984-22.68652,14.50984-22.68652,1.5,5.3125,1.5,5.3125Z"
          fill="#2f2e41"
        />
        <path
          d="M485.6027,434.3125l19.05906-2,2.94094,11s31,28,15,30c-16,2-31-9-31-9l-22.00984-12.62598s1.50984-23.68652,7.50984-23.68652,8.5,6.3125,8.5,6.3125Z"
          fill="#2f2e41"
        />
        <circle cx="433.55089" cy="65.9109" r="22.31062" fill="#ffb6b6" />
        <path
          d="M442.1033,40.99737s3.71844,10.41162-2.23106,12.64269-14.13006,5.20581-13.38637,8.92425-2.23106,14.87375-2.23106,14.87375c0,0,17.8485,11.15531,5.9495,22.31062-11.899,11.15531-20.82325,6.5585-18.96403,17.40931s-16.73297,6.38869-16.73297,6.38869c0,0-6.69319-10.41162-1.48737-20.82325,5.20581-10.41162-3.71844,8.18056-5.9495,8.18056s-13.38637,2.97475-8.92425-9.66794,11.15531-13.38637,11.15531-23.05431c0-9.66794,4.46212-21.56694,5.9495-23.05431s8.18056-20.82325,21.56694-19.33587,25.28537,5.20581,25.28537,5.20581Z"
          fill="#2f2e41"
        />
        <path d="M247.1027,408.8125s-14,26-13,25,18,5,18,5l10-26-15-4Z" fill="#a0616a" />
        <path
          d="M351.1027,222c-31.63831,4.44458-60.21521,.56946-83.5-17.6875,0,0-17,57,14,63,31,6,65.5-4.3125,69.5-14.3125s0-31,0-31Z"
          fill="#2f2e41"
        />
        <polygon
          points="292.1027 240 257.6027 321.3125 241.6027 415.3125 262.6027 420.3125 300.16569 333.8125 351.1027 251 292.1027 240"
          fill="#2f2e41"
        />
        <path
          d="M248.6027,434.3125l-19.05906-2-2.94094,11s-31,28-15,30,31-9,31-9l22.00984-12.62598s-1.50984-23.68652-7.50984-23.68652-8.5,6.3125-8.5,6.3125Z"
          fill="#2f2e41"
        />
        <path
          d="M278.71441,395.13321s-5.296,29.05086-4.65328,27.79113,18.66453-.79697,18.66453-.79697l1.49111-27.81684-15.50236,.82268Z"
          fill="#a0616a"
        />
        <polygon
          points="269.44 220.67219 261.70809 308.66191 275.48796 403.01293 297.00616 401.29045 306.05129 307.42132 328.9557 212.93387 269.44 220.67219"
          fill="#2f2e41"
        />
        <path
          d="M288.00811,418.92663l-18.74642,3.97736,.59606,11.37075s-20.84978,36.19788-5.0132,33.16425c15.83657-3.03363,26.71137-18.12465,26.71137-18.12465l17.04109-18.80026s-8.7436-22.06536-14.45093-20.21433-6.13796,8.62688-6.13796,8.62688Z"
          fill="#2f2e41"
        />
        <path
          d="M287.47229,38.54343s-9.5367,5.59286-6.40901,11.12384,7.48797,13.06482,4.52991,15.43753-7.84181,12.83402-7.84181,12.83402c0,0-20.84546-2.91147-18.88847,13.28104s11.74981,18.4001,3.35622,25.52361c-8.39359,7.12351,8.72353,15.64313,8.72353,15.64313,0,0,11.81694-3.68246,14.51291-15.00651s-2.40329,8.65867-.6931,10.09147,8.3507,10.87704,13.04953-1.6796,.04587-17.42509,6.25467-24.83589c6.20879-7.4108,10.43002-19.39737,10.24509-21.49269s7.10211-21.21531-4.11419-28.67198c-11.2163-7.45667-22.72528-12.24795-22.72528-12.24795Z"
          fill="#2f2e41"
        />
        <polygon
          points="291.8527 83.00787 285.1027 107 322.14994 107 309.9506 77.43806 291.8527 83.00787"
          fill="#a0616a"
        />
        <polygon
          points="291.8527 83.00787 285.1027 107 322.14994 107 309.9506 77.43806 291.8527 83.00787"
          opacity=".1"
        />
        <g>
          <path
            id="uuid-30591428-cd87-4ca8-90c0-8a5e265d7e31-263"
            d="M372.64528,260.14928c.47825,7.17139-2.83434,13.23116-7.39831,13.53477s-8.65017-5.2634-9.12763-12.43741c-.23003-2.86311,.22893-5.73967,1.33852-8.38903l-1.64263-30.41108,14.28746-.46665,.10246,30.02948c1.45178,2.4795,2.28851,5.27069,2.44012,8.13992Z"
            fill="#a0616a"
          />
          <path
            d="M329.62391,141.53126s.79011-22.36612,9.30155-23.73912c8.51144-1.373,20.01953,8.71962,20.01953,8.71962l17.58081,72.82555-2.68236,45.75973-19.24177-1.08692-4.51599-43.35379-20.46177-59.12508Z"
            fill={COLOR_PRIMARY}
          />
        </g>
        <path
          d="M282.1027,99h34.95276l38.04724,26,11,91-6,18-11,21s11-46-16-28c-.27,.18-.5375,.3585-.80252,.53551-17.38039,11.60857-40.3139,10.41672-56.40234-2.92491l-12.79514-10.61061,7-35-5.5-64.5,17.5-15.5Z"
          fill={COLOR_PRIMARY}
        />
        <g>
          <path
            id="uuid-53b096d6-03cc-438c-8f9a-f5acbbdd06bd-264"
            d="M194.64176,212.92958c-5.60599,4.49783-12.47087,5.25115-15.33273,1.683-2.86186-3.56816-.6369-10.1056,4.97169-14.60428,2.21575-1.82777,4.837-3.09834,7.64445-3.70538l23.99348-18.75753,8.56236,11.44706-24.56237,17.27629c-1.20177,2.60986-3.01121,4.89388-5.27688,6.66085Z"
            fill="#a0616a"
          />
          <path
            d="M267.26582,109.74615s18.79023-12.15709,24.78885-5.96467,4.31227,21.40602,4.31227,21.40602l-49.64409,56.10803-39.05388,23.99881-10.12501-16.39852,32.96011-28.52326,36.76175-50.62641Z"
            fill={COLOR_PRIMARY}
          />
        </g>
        <circle cx="300.6545" cy="65.9109" r="22.31062" fill="#a0616a" />
        <path
          d="M289.54777,50.9563s-3.28686-10.9563,2.19128-10.9563,27.39066,1.09563,29.58191,3.28688,28.12051,24.1831,12.78175,41.71312c-15.33877,17.53002,0,26,0,26,0,0,11.32203,26.51074,.36577,28.70199-10.95626,2.19125-55.89775-10.33834-44.9207-30.67758,11.91235-22.07227,17.45483-56.90991,0-58.06812Z"
          fill="#2f2e41"
        />
        <g>
          <path
            d="M654.62748,213.83563c-.35419-1.08385-3.03868-.60669-3.29642-1.57612-.25685-.96594,2.31912-1.77589,4.29475-4.49802,.3563-.49089,2.60143-3.5844,1.74035-4.88552-1.63216-2.46625-12.98817,3.67622-15.71401,.22927-.59824-.75646-.84437-2.05513-2.62649-4.47199-.70898-.96147-1.16382-1.42564-1.70728-1.4119-.77113,.01948-1.04704,.98418-2.25903,2.73166-1.81761,2.6207-2.47353,2.29167-3.67091,4.43894-.88887,1.59402-.9942,2.61253-1.63519,2.67256-.90705,.08497-1.28185-1.89965-2.3902-1.97665-1.13101-.07854-2.26276,1.88246-2.8238,3.41457-1.05285,2.8751-.40089,5.07265-.25634,7.26272,.15706,2.38005-.21174,5.86405-2.79775,10.33581l-24.39164,38.86187c5.23835-7.92231,20.10799-29.52575,26.09893-37.44997,1.72948-2.28755,3.58802-4.58456,6.44861-5.0167,2.75409-.41608,6.10513,.9465,10.78244,.86718,.54625-.00924,2.06647-.0544,2.43625-.86016,.30573-.66614-.45336-1.24533-.17722-1.8715,.37038-.83991,2.03679-.47957,4.30777-1.03737,1.60136-.39336,2.6893-1.04324,3.62486-1.60214,.28206-.1685,4.48389-2.71375,4.01237-4.15656h-.00004Z"
            fill="#f2f2f2"
          />
          <path
            d="M590.74128,222.90898c-.63416-.1389-1.17451,1.31635-1.72468,1.16314-.54821-.15264-.20248-1.65064-.96847-3.40572-.13813-.3165-1.00865-2.31103-1.89572-2.2638-1.68137,.08948-1.94319,7.43538-4.42147,7.77869-.54387,.07536-1.25231-.17855-2.9527-.00121-.67646,.07056-1.03551,.15987-1.18558,.43056-.21293,.38409,.18092,.79788,.68891,1.8969,.76182,1.64824,.41106,1.87525,1.11947,3.08248,.52589,.89619,.99539,1.24177,.8399,1.57368-.22,.4697-1.30221,.08104-1.65979,.60279-.36488,.53245,.27105,1.65372,.86113,2.37114,1.10735,1.34631,2.37402,1.66038,3.49063,2.22135,1.21346,.60963,2.81703,1.7959,4.26564,4.35538l12.03589,23.18446c-2.3769-4.85686-8.68964-18.38826-10.85031-23.61505-.62375-1.50886-1.21488-3.08381-.60158-4.61251,.59043-1.47178,2.22607-2.72333,3.5367-5.04187,.15308-.27078,.56954-1.02994,.28078-1.44392-.23873-.34225-.74203-.13679-.96968-.453-.30536-.42414,.35231-1.13805,.73376-2.4136,.26898-.89945,.26391-1.62093,.25955-2.24138-.00132-.18705-.03819-2.98371-.88236-3.1686v.00006Z"
            fill="#f2f2f2"
          />
          <path
            d="M602.7489,228.56582l.37832-3.49289,.23204-.21944c1.07353-1.01459,1.74511-2.08947,1.99703-3.19576,.04009-.17587,.07113-.35379,.1029-.53496,.12563-.71817,.28159-1.61095,.97206-2.53825,.38642-.51664,1.40861-1.68432,2.46285-1.27646,.28454,.10665,.47659,.29111,.61001,.48669,.03349-.03397,.06781-.06778,.1045-.10451,.45539-.44254,.77396-.60465,1.08083-.76077,.2358-.12014,.47929-.24373,.89134-.5733,.18077-.1445,.32552-.27458,.44749-.38383,.36992-.33031,.84308-.71708,1.4912-.56388,.68704,.18779,.9226,.84315,1.07888,1.27702,.27909,.77389,.36506,1.30609,.42193,1.65905,.02048,.12894,.04423,.27414,.0622,.32134,.15038,.379,2.03808,.47702,2.74835,.51798,1.59658,.08663,2.97812,.1616,3.31036,1.25481,.2384,.78209-.25846,1.63511-1.51932,2.60275-.39275,.30105-.78541,.50883-1.12749,.664,.21041,.19894,.37804,.4695,.3883,.83799h0c.0231,.87225-.87152,1.54156-2.65888,1.99276-.44416,.11339-1.04159,.26343-1.82338,.22552-.36818-.01821-.6907-.07459-.96567-.12728-.041,.15667-.11618,.31955-.25171,.47414-.39781,.45538-1.04027,.57117-1.91232,.31448-.96274-.26828-1.72342-.63312-2.39463-.95383-.5863-.27931-1.09182-.51816-1.516-.58341-.78894-.11033-1.52462,.37678-2.40503,1.02507l-2.20612,1.65499h-.00002Z"
            fill="#f2f2f2"
          />
          <path
            d="M609.04516,208.67108l-3.47462,.52002-.27111-.16879c-1.25371-.78108-2.46376-1.15805-3.59778-1.12111-.1803,.00584-.36025,.02093-.54357,.03616-.72655,.06063-1.6297,.13624-2.7018-.29644-.59778-.24273-1.98653-.93531-1.85941-2.05852,.03099-.30227,.16071-.53484,.31606-.7135-.04138-.02377-.08278-.04838-.1276-.07456-.54357-.32826-.7812-.59529-1.01005-.85253-.17602-.19762-.35734-.40179-.78064-.71678-.18563-.13821-.34817-.24522-.48479-.33548-.41335-.27401-.90747-.63364-.92367-1.29942,.00738-.7122,.58157-1.10629,.9616-1.36752,.6778-.46625,1.17077-.68442,1.49777-.82896,.11952-.0525,.25396-.11232,.29506-.14167,.32846-.24159-.05553-2.09241-.19606-2.78985-.32117-1.56633-.5991-2.9217,.37411-3.52036,.69605-.42897,1.6472-.16474,2.903,.80945,.39082,.30354,.69141,.63066,.92828,.9222,.13906-.25398,.35825-.48479,.7121-.58814h0c.83787-.24358,1.7122,.452,2.60201,2.06645,.22234,.40087,.51902,.94071,.68064,1.70654,.07577,.36075,.10303,.68702,.12182,.96639,.16196-.00009,.33856,.03133,.52248,.12321,.54138,.26929,.81637,.86137,.78926,1.77001-.0153,.9993-.17526,1.82765-.31524,2.55826-.12147,.63798-.22429,1.18755-.17979,1.6144,.0934,.79113,.75118,1.37918,1.60159,2.06637l2.16045,1.71418h-.00009Z"
            fill="#f2f2f2"
          />
          <path
            d="M609.51348,198.2368l.37832-3.49289,.23204-.21944c1.07353-1.01459,1.74511-2.08947,1.99703-3.19576,.04009-.17587,.07113-.35379,.1029-.53496,.12563-.71817,.28159-1.61095,.97206-2.53825,.38642-.51664,1.40861-1.68432,2.46285-1.27646,.28454,.10665,.47659,.29111,.61001,.48669,.03349-.03397,.06781-.06778,.1045-.10451,.45539-.44254,.77396-.60465,1.08083-.76077,.2358-.12014,.47929-.24373,.89134-.5733,.18077-.1445,.32552-.27458,.44749-.38383,.36992-.33031,.84308-.71708,1.4912-.56388,.68704,.18779,.9226,.84315,1.07888,1.27702,.27909,.77389,.36506,1.30609,.42193,1.65905,.02048,.12894,.04423,.27414,.0622,.32134,.15038,.379,2.03808,.47702,2.74835,.51798,1.59658,.08663,2.97812,.1616,3.31036,1.25481,.2384,.78209-.25846,1.63511-1.51932,2.60275-.39275,.30105-.78541,.50883-1.12749,.664,.21041,.19894,.37804,.4695,.3883,.83799h0c.0231,.87225-.87152,1.54156-2.65888,1.99276-.44416,.11339-1.04159,.26343-1.82338,.22552-.36818-.01821-.6907-.07459-.96567-.12728-.041,.15667-.11618,.31955-.25171,.47414-.39781,.45538-1.04027,.57117-1.91232,.31448-.96274-.26828-1.72342-.63312-2.39463-.95383-.5863-.27931-1.09182-.51816-1.516-.58341-.78894-.11033-1.52462,.37678-2.40503,1.02507l-2.20612,1.65499h-.00002Z"
            fill="#f2f2f2"
          />
          <path
            d="M598.24672,265.16175l-.61431-.60685,.01109-.8629-.01109,.8629-.85388-.09027c.00408-.08028-.00153-.26809-.00778-.56139-.03285-1.60682-.13482-6.49713,.54614-14.01104,.47549-5.24516,1.27005-10.56941,2.36162-15.82744,1.0934-5.26624,2.22818-9.18461,3.13963-12.33401,.68784-2.37536,1.38211-4.62838,2.05984-6.82293,1.80866-5.86329,3.51641-11.40187,4.57396-17.71941,.23682-1.41021,.72832-4.34583-.27798-7.81072-.58387-2.00954-1.58355-3.90108-2.97231-5.62031l1.34413-1.0855c1.53244,1.89982,2.63793,3.99462,3.28636,6.22585,1.11574,3.84165,.58022,7.04039,.32292,8.5773-1.07639,6.42907-2.80118,12.02087-4.62763,17.94063-.67427,2.18666-1.36631,4.42981-2.05113,6.79549-.90321,3.12018-2.02779,7.00451-3.10696,12.20312-1.07926,5.19512-1.86364,10.45429-2.33386,15.63327-.67194,7.4192-.571,12.23674-.53795,13.81941,.01708,.84502,.02077,1.02202-.2508,1.29483v-.00002Z"
            fill="#f2f2f2"
          />
          <path
            d="M602.51436,185.74532c-.06911-.01541-.13867-.03265-.20832-.05338-1.41759-.39736-2.55471-1.55264-3.38069-3.43309-.3866-.88425-.47786-1.81489-.65983-3.67089-.02857-.2862-.15473-1.73418,.00037-3.65318,.10091-1.25399,.23571-1.75764,.57696-2.15309,.37896-.44058,.88952-.69289,1.42905-.81956,.01459-.16603,.06891-.32837,.17666-.48226,.44384-.63856,1.18677-.37612,1.58988-.23958,.2031,.07294,.45619,.16566,.74088,.2136,.44739,.07734,.71454-.00128,1.11892-.11836,.38693-.11234,.86804-.25204,1.53301-.24018,1.31219,.01803,2.28353,.60778,2.60385,.80185,1.68691,1.01362,2.26934,2.63075,2.94336,4.5032,.13393,.37533,.57841,1.73194,.68191,3.47889,.07472,1.26033-.09043,1.77624-.2798,2.1451-.38679,.75839-.97906,1.10993-2.44902,1.84956-1.53571,.77504-2.30622,1.16285-2.93798,1.36221-1.47009,.4615-2.39322,.75133-3.47921,.50911v.00004Z"
            fill="#f2f2f2"
          />
        </g>
        <g>
          <path
            d="M167.15961,204.69244l-1.12829-35.59283c-.01831-.57773,.20876-1.14021,.62318-1.5432,.41465-.40319,.98354-.6146,1.56094-.58l28.24413,1.69397c.57061,.03392,1.10601,.30678,1.46886,.7483,.36308,.44131,.5274,1.01915,.4509,1.58536l-4.77799,35.37632c-.14456,1.07039-1.10178,1.85273-2.1793,1.78118l-22.33782-1.47715c-.22662-.01503-.44244-.06549-.6423-.14633-.73773-.29841-1.2557-1.00751-1.28231-1.84561Z"
            fill={COLOR_PRIMARY}
          />
          <path
            d="M166.06642,165.76997c.39515-.04162,.92446-.09278,1.55992-.14615-.16988-.31634-.2394-.69041-.16677-1.07437l.50439-2.66606c.13091-.69193,.68838-1.22238,1.38638-1.31573,4.15872-.55609,18.46272-2.06481,27.46966,2.02147,.56351,.25568,.92604,.81706,.94397,1.43557l.08241,2.84428c.01016,.35169-.09201,.67764-.27111,.94787,.74877,.20134,1.49511,.41425,2.23546,.6464,.46395,.14548,.79603,.55495,.85181,1.03797l.13993,1.21151c.08876,.76878-.54147,1.42973-1.31358,1.37756l-33.76634-2.28036c-.76842-.05189-1.30358-.78519-1.11867-1.5328l.38904-1.57284c.12533-.50673,.55439-.87967,1.0735-.93433Z"
            fill="#3f3d56"
          />
        </g>
        <g>
          <path
            d="M82.15961,103.69244l-1.12829-35.59283c-.01831-.57773,.20876-1.14021,.62318-1.5432,.41465-.40319,.98354-.6146,1.56094-.58l28.24413,1.69397c.57061,.03392,1.10601,.30678,1.46886,.7483,.36308,.44131,.5274,1.01915,.4509,1.58536l-4.77799,35.37632c-.14456,1.07039-1.10178,1.85273-2.1793,1.78118l-22.33782-1.47715c-.22662-.01503-.44244-.06549-.6423-.14633-.73773-.29841-1.2557-1.00751-1.28231-1.84561Z"
            fill={COLOR_PRIMARY}
          />
          <path
            d="M81.06642,64.76997c.39515-.04162,.92446-.09278,1.55992-.14615-.16988-.31634-.2394-.69041-.16677-1.07437l.50439-2.66606c.13091-.69193,.68838-1.22238,1.38638-1.31573,4.15872-.55609,18.46272-2.06481,27.46966,2.02147,.56351,.25568,.92604,.81706,.94397,1.43557l.08241,2.84428c.01016,.35169-.09201,.67764-.27111,.94787,.74877,.20134,1.49511,.41425,2.23546,.6464,.46395,.14548,.79603,.55495,.85181,1.03797l.13993,1.21151c.08876,.76878-.54147,1.42973-1.31358,1.37756l-33.76634-2.28036c-.76842-.05189-1.30358-.78519-1.11867-1.5328l.38904-1.57284c.12533-.50673,.55439-.87967,1.0735-.93433Z"
            fill="#3f3d56"
          />
        </g>
        <g>
          <path
            d="M525.15961,213.69244l-1.12829-35.59283c-.01831-.57773,.20876-1.14021,.62318-1.5432,.41465-.40319,.98354-.6146,1.56094-.58l28.24413,1.69397c.57061,.03392,1.10601,.30678,1.46886,.7483,.36308,.44131,.5274,1.01915,.4509,1.58536l-4.77799,35.37632c-.14456,1.07039-1.10178,1.85273-2.1793,1.78118l-22.33782-1.47715c-.22662-.01503-.44244-.06549-.6423-.14633-.73773-.29841-1.2557-1.00751-1.28231-1.84561Z"
            fill={COLOR_PRIMARY}
          />
          <path
            d="M524.06642,174.76997c.39515-.04162,.92446-.09278,1.55992-.14615-.16988-.31634-.2394-.69041-.16677-1.07437l.50439-2.66606c.13091-.69193,.68838-1.22238,1.38638-1.31573,4.15872-.55609,18.46272-2.06481,27.46966,2.02147,.56351,.25568,.92604,.81706,.94397,1.43557l.08241,2.84428c.01016,.35169-.09201,.67764-.27111,.94787,.74877,.20134,1.49511,.41425,2.23546,.6464,.46395,.14548,.79603,.55495,.85181,1.03797l.13993,1.21151c.08876,.76878-.54147,1.42973-1.31358,1.37756l-33.76634-2.28036c-.76842-.05189-1.30358-.78519-1.11867-1.5328l.38904-1.57284c.12533-.50673,.55439-.87967,1.0735-.93433Z"
            fill="#3f3d56"
          />
        </g>
      </svg>
    </Box>
  );
};

export { WelcomeIllustration };
