import { FC } from 'react';
import { Box, SxProps } from '@mui/material';

type AdminIllustrationProps = {
  sx?: SxProps;
};

const AdminIllustration: FC<AdminIllustrationProps> = ({ sx }) => (
  <Box sx={sx}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <circle fill="#324A5E" cx="256" cy="256" r="256" />
      <g>
        <path
          fill="#2B3B4E"
          d="M288.168,509.969c54.42-6.854,107.063-31.161,148.851-72.951
		c38.581-38.581,62.259-86.416,71.061-136.359L335.127,127.707L205.895,348.232l-102.46-102.46L84.04,244.65L57.41,279.211
		L288.168,509.969z"
        />
        <path
          fill="#2B3B4E"
          d="M407.704,119.203l-4.503,16.217l-7.216,19.852l115.571,115.571
		c1.076-18.635,0.126-37.362-2.853-55.827l-82.036-82.035l-2.881,1.184L407.704,119.203z"
        />
      </g>
      <path
        fill="#859CAF"
        d="M152.423,227.556c-11.345,0-20.544,9.197-20.544,20.544v158.025c0,11.345,9.197,20.544,20.544,20.544
	H256V227.556H152.423z"
      />
      <path
        fill="#708999"
        d="M359.579,227.556H256v199.111h103.577c11.345,0,20.544-9.197,20.544-20.544V248.098
	C380.121,236.753,370.924,227.556,359.579,227.556z"
      />
      <path
        fill="#B8C9D9"
        d="M163.916,256h-22.699c-9.442,0-17.094-7.654-17.094-17.096v-51.143
	c-0.002-9.44,7.652-17.094,17.094-17.094h22.699c9.442,0,17.096,7.654,17.096,17.094v51.143C181.01,248.346,173.356,256,163.916,256
	z"
      />
      <path
        fill="#9BADBC"
        d="M370.785,256h-22.699c-9.442,0-17.096-7.654-17.096-17.096v-51.143
	c0-9.442,7.654-17.094,17.096-17.094h22.699c9.442,0,17.094,7.654,17.094,17.094v51.143C387.879,248.346,380.225,256,370.785,256z"
      />
      <g>
        <circle fill="#FED8B2" cx="172.597" cy="233.417" r="20.052" />
        <circle fill="#FED8B2" cx="335.127" cy="233.417" r="20.052" />
      </g>
      <path
        fill="#F4E3C3"
        d="M258.26,316.926h-4.518c-41.796,0-75.678-33.882-75.678-75.678v-69.096
	c0-41.796,33.882-75.678,75.678-75.678h4.518c41.796,0,75.678,33.882,75.678,75.678v69.096
	C333.938,283.045,300.054,316.926,258.26,316.926z"
      />
      <path
        fill="#FED8B2"
        d="M258.26,96.475H256v220.451h2.26c41.796,0,75.678-33.882,75.678-75.678v-69.096
	C333.938,130.357,300.054,96.475,258.26,96.475z"
      />
      <circle fill="#59595B" cx="217.126" cy="218.074" r="7.794" />
      <circle fill="#272525" cx="290.599" cy="218.074" r="7.794" />
      <path
        fill="#B8C9D9"
        d="M325.154,323.558H285.11c-2.22,0-4.02-1.8-4.02-4.02v-27.822h-50.176v27.822
	c0,2.22-1.8,4.02-4.02,4.02h-40.045c-32.153,0-58.218,26.065-58.218,58.218v96.337C166.14,499.671,209.63,512,256,512
	s89.86-12.329,127.372-33.887v-96.337C383.372,349.624,357.307,323.558,325.154,323.558z"
      />
      <path
        fill="#9BADBC"
        d="M383.372,381.776c0-32.153-26.065-58.218-58.218-58.218H285.11c-2.22,0-4.02-1.8-4.02-4.02v-27.822
	H256V512c46.37,0,89.859-12.329,127.371-33.887v-96.337H383.372z"
      />
      <path
        fill="#FD8469"
        d="M253.862,274.335c-14.043,0-25.464-11.423-25.464-25.464c0-2.381,1.929-4.31,4.31-4.31
	s4.31,1.929,4.31,4.31c0,9.288,7.558,16.844,16.844,16.844c9.287,0,16.844-7.558,16.844-16.844c0-2.381,1.929-4.31,4.31-4.31
	s4.31,1.929,4.31,4.31C279.326,262.913,267.904,274.335,253.862,274.335z"
      />
      <path
        fill="#FC6F58"
        d="M279.326,248.87c0-2.381-1.929-4.31-4.31-4.31s-4.31,1.929-4.31,4.31
	c0,8.563-6.427,15.638-14.707,16.694v8.663C269.043,273.134,279.326,262.191,279.326,248.87z"
      />
      <path
        fill="#CFDBE6"
        d="M256,95.677c-62.838,0-113.778,50.94-113.778,113.778S193.162,323.232,256,323.232
	s113.778-50.94,113.778-113.778S318.838,95.677,256,95.677z M332.878,204.486l-14.836,59.34
	c-3.029,12.117-13.915,20.618-26.407,20.618H220.36c-12.49,0-23.376-8.501-26.407-20.618l-14.836-59.34
	c-4.291-17.179,8.702-33.82,26.41-33.82h100.945C324.18,170.667,337.174,187.308,332.878,204.486z"
      />
      <path
        fill="#B8C9D9"
        d="M369.778,209.455c0-62.838-50.94-113.778-113.778-113.778v74.99h50.473
	c17.708,0,30.701,16.641,26.407,33.82l-14.836,59.34c-3.029,12.117-13.915,20.618-26.407,20.618H256v38.788
	C318.838,323.232,369.778,272.293,369.778,209.455z"
      />
      <path
        fill="#CFDBE6"
        d="M301.828,345.643h-91.655c-6.11,0-11.061-4.953-11.061-11.062v-26.146
	c0-6.11,4.953-11.062,11.061-11.062h91.655c6.11,0,11.061,4.953,11.061,11.062v26.146
	C312.889,340.69,307.936,345.643,301.828,345.643z"
      />
      <path
        fill="#B8C9D9"
        d="M301.828,297.374H256v48.269h45.827c6.11,0,11.062-4.953,11.062-11.062v-26.146
	C312.889,302.327,307.936,297.374,301.828,297.374z"
      />
      <rect x="199.111" y="313.751" fill="#E6F3FF" width="113.778" height="15.515" />
      <rect x="256" y="313.751" fill="#CFDBE6" width="56.889" height="15.515" />
      <path
        fill="#FC6F58"
        d="M341.333,426.667h42.037v-44.891c0-4.113-0.433-8.126-1.245-11.998h-40.793V426.667z"
      />
      <rect x="199.111" y="369.778" fill="#CFDBE6" width="113.778" height="113.778" />
      <rect x="256" y="369.778" fill="#B8C9D9" width="56.889" height="113.778" />
      <g>
        <polygon
          fill="#FFD15D"
          points="74.99,225.106 83.78,242.917 103.434,245.772 89.212,259.636 92.57,279.211 74.99,269.969 
		57.41,279.211 60.768,259.636 46.545,245.772 66.2,242.917 	"
        />
        <polygon
          fill="#FFD15D"
          points="426.667,267.802 432.526,279.674 445.63,281.579 436.148,290.821 438.386,303.871 
		426.667,297.71 414.948,303.871 417.185,290.821 407.704,281.579 420.807,279.674 	"
        />
        <polygon
          fill="#FFD15D"
          points="407.704,119.203 413.563,131.075 426.667,132.98 417.185,142.222 419.423,155.272 
		407.704,149.111 395.985,155.272 398.222,142.222 388.741,132.98 401.844,131.075 	"
        />
      </g>
      <g>
        <polygon
          fill="#F9B54C"
          points="74.99,225.106 83.78,242.917 103.434,245.772 89.212,259.636 92.57,279.211 74.99,269.969 	
		"
        />
        <polygon
          fill="#F9B54C"
          points="426.667,267.802 432.526,279.674 445.63,281.579 436.148,290.821 438.386,303.871 
		426.667,297.71 	"
        />
        <polygon
          fill="#F9B54C"
          points="407.704,119.203 413.563,131.075 426.667,132.98 417.185,142.222 419.423,155.272 
		407.704,149.111 	"
        />
      </g>
    </svg>
  </Box>
);

export { AdminIllustration };
