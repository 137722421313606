import { FC } from 'react';
import { Box, SxProps } from '@mui/material';

type StudentIllustrationProps = {
  sx?: SxProps;
};

const StudentIllustration: FC<StudentIllustrationProps> = ({ sx }) => (
  <Box sx={sx}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512.002 512.002"
      xmlSpace="preserve"
    >
      <circle fill="#B5F1F4" cx="256.001" cy="256" r="256" />
      <path
        fill="#84DBFF"
        d="M507.4,304.459L318.687,115.746l-28.082,35.957l-71.632,91.276l-61.945,3.069l49.928,49.928
	l-5.218,2.172l50.012,50.012l130.562,130.548C446.311,442.33,493.086,379.164,507.4,304.459z"
      />
      <path
        fill="#D88B03"
        d="M337.948,483.556h-63.91c-9.962,0-18.039-8.076-18.039-18.039V274.039
	c0-9.962,8.076-18.039,18.039-18.039h63.91c9.962,0,18.039,8.076,18.039,18.039v191.479
	C355.987,475.479,347.911,483.556,337.948,483.556z"
      />
      <path
        fill="#F9B54C"
        d="M237.962,483.556h-63.91c-9.962,0-18.039-8.076-18.039-18.039V274.039
	c0-9.962,8.076-18.039,18.039-18.039h63.91c9.962,0,18.039,8.076,18.039,18.039v191.479
	C256.001,475.479,247.924,483.556,237.962,483.556z"
      />
      <path
        fill="#324A5E"
        d="M325.155,323.558h-40.045c-2.22,0-4.02-1.8-4.02-4.02v-27.822h-50.176v27.822
	c0,2.22-1.8,4.02-4.02,4.02H186.85c-32.153,0-58.218,26.065-58.218,58.218v96.337C166.141,499.671,209.631,512,256.001,512
	s89.86-12.329,127.372-33.887v-96.337C383.373,349.624,357.308,323.558,325.155,323.558z"
      />
      <path
        fill="#2B3B4E"
        d="M325.155,323.558h-40.045c-2.22,0-4.02-1.8-4.02-4.02v-27.822h-26.524v220.262
	c0.479,0.002,0.955,0.024,1.436,0.024c46.37,0,89.86-12.329,127.372-33.887v-96.339
	C383.373,349.624,357.308,323.558,325.155,323.558z"
      />
      <circle fill="#FED8B2" cx="172.598" cy="233.417" r="20.052" />
      <circle fill="#EABE96" cx="335.128" cy="233.417" r="20.052" />
      <path
        fill="#F4E3C3"
        d="M256.123,316.926h-4.518c-41.796,0-75.678-33.882-75.678-75.678v-69.096
	c0-41.796,33.882-75.678,75.678-75.678h4.518c41.796,0,75.678,33.882,75.678,75.678v69.096
	C331.801,283.045,297.918,316.926,256.123,316.926z"
      />
      <path
        fill="#FED8B2"
        d="M256.123,96.475h-1.558v220.451h1.558c41.796,0,75.678-33.882,75.678-75.678v-69.096
	C331.801,130.357,297.918,96.475,256.123,96.475z"
      />
      <circle fill="#59595B" cx="217.127" cy="224.521" r="7.794" />
      <circle fill="#272525" cx="290.6" cy="224.521" r="7.794" />
      <path
        fill="#FD8469"
        d="M253.863,288.39c-14.043,0-25.464-11.423-25.464-25.464c0-2.381,1.929-4.31,4.31-4.31
	s4.31,1.929,4.31,4.31c0,9.288,7.558,16.844,16.844,16.844s16.844-7.558,16.844-16.844c0-2.381,1.929-4.31,4.31-4.31
	s4.31,1.929,4.31,4.31C279.327,276.968,267.904,288.39,253.863,288.39z"
      />
      <path
        fill="#FC6F58"
        d="M279.327,262.927c0-2.381-1.929-4.31-4.31-4.31s-4.31,1.929-4.31,4.31
	c0,9.051-7.182,16.437-16.143,16.81v8.62C268.28,287.978,279.327,276.732,279.327,262.927z"
      />
      <path
        fill="#935635"
        d="M323.593,122.049c-13.215-18.825-34.123-31.835-58.159-34.494
	c-23.695-3.465-71.201,1.245-151.655,54.667c0,0,19.646,38.007,55.467,54.091v37.107h12.062v-32.775
	c13.574,3.701,29.036,4.022,46.249-1.536c16.022-5.173,51.465-23.092,57.889-26.302c11.352,13.038,24.462,24.678,38.934,34.121
	v26.491h14.102v-43.268v-20.963C338.481,151.645,332.963,135.397,323.593,122.049z"
      />
      <path
        fill="#804000"
        d="M323.593,122.049c-13.215-18.825-34.123-31.835-58.159-34.494c-3.181-0.465-6.806-0.774-10.868-0.846
	V187.82c13.753-6.384,27.167-13.155,30.88-15.012c11.352,13.038,24.462,24.678,38.934,34.121v26.491h14.102v-43.268v-20.963
	C338.481,151.645,332.963,135.397,323.593,122.049z"
      />
      <path
        fill="#2B3B4E"
        d="M199.112,398.222c13.538-25.641,7.746-58.154,3.324-74.664h-15.589
	c-32.153,0-58.218,26.065-58.218,58.218v96.337c19.204,11.036,39.984,19.635,61.907,25.417
	C183.912,464.801,184.783,425.36,199.112,398.222z"
      />
      <path
        fill="#1E2C3A"
        d="M312.89,398.222c-13.538-25.641-7.745-58.154-3.324-74.664h15.589
	c32.153,0,58.218,26.065,58.218,58.218v96.337c-19.204,11.036-39.984,19.635-61.907,25.417
	C328.089,464.801,327.219,425.36,312.89,398.222z"
      />
    </svg>
  </Box>
);

export { StudentIllustration };
