import { FC } from 'react';
import { Box, SxProps } from '@mui/material';

type TeacherIllustrationProps = {
  sx?: SxProps;
};

const TeacherIllustration: FC<TeacherIllustrationProps> = ({ sx }) => (
  <Box sx={sx}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <circle fill="#B5F1F4" cx="256" cy="256" r="256" />
      <path
        fill="#84DBFF"
        d="M345.709,495.825C442.842,459.474,512,365.82,512,256c0-8.959-0.464-17.81-1.362-26.531
	l-55.527-55.527l-25.893,52L315.077,111.8l-40.058,37.893L142.222,292.338L345.709,495.825z"
      />
      <rect x="332.714" y="173.942" fill="#FFFFFF" width="122.397" height="195.836" />
      <rect x="391.62" y="173.942" fill="#E6F3FF" width="63.497" height="195.836" />
      <g>
        <rect x="346.074" y="224.453" fill="#CFDBE6" width="95.677" height="6.896" />
        <rect x="346.074" y="252.897" fill="#CFDBE6" width="95.677" height="6.896" />
        <rect x="346.074" y="281.341" fill="#CFDBE6" width="95.677" height="6.896" />
        <rect x="346.074" y="309.786" fill="#CFDBE6" width="95.677" height="6.896" />
      </g>
      <g>
        <rect x="391.62" y="224.453" fill="#B8C9D9" width="50.131" height="6.896" />
        <rect x="391.62" y="252.897" fill="#B8C9D9" width="50.131" height="6.896" />
        <rect x="391.62" y="281.341" fill="#B8C9D9" width="50.131" height="6.896" />
        <rect x="391.62" y="309.786" fill="#B8C9D9" width="50.131" height="6.896" />
      </g>
      <path
        fill="#FD8469"
        d="M227.556,93.227c0,0-56.315-39.65-85.333,28.444s0,170.667,0,170.667h56.889L227.556,93.227z"
      />
      <path
        fill="#FED8B2"
        d="M281.088,319.538v-27.822h-50.176v27.822c0,2.22-1.8,4.02-4.02,4.02h-34.576v96.011h127.371v-96.011
	H285.11C282.888,323.558,281.088,321.758,281.088,319.538z"
      />
      <path
        fill="#EABE96"
        d="M319.686,323.558H285.11c-2.22,0-4.02-1.8-4.02-4.02v-27.822h-27.388v127.853h65.984V323.558z"
      />
      <circle fill="#FED8B2" cx="172.597" cy="233.417" r="20.052" />
      <circle fill="#EABE96" cx="335.127" cy="233.417" r="20.052" />
      <path
        fill="#F4E3C3"
        d="M256.122,316.926h-4.518c-41.796,0-75.678-33.882-75.678-75.678v-69.096
	c0-41.796,33.882-75.678,75.678-75.678h4.518c41.796,0,75.678,33.882,75.678,75.678v69.096
	C331.8,283.045,297.917,316.926,256.122,316.926z"
      />
      <path
        fill="#FED8B2"
        d="M256.122,96.475h-2.42v220.451h2.42c41.796,0,75.678-33.882,75.678-75.678v-69.096
	C331.8,130.357,297.917,96.475,256.122,96.475z"
      />
      <g>
        <path
          fill="#FC6F58"
          d="M324.377,206.931v26.491h14.102v-43.268v-20.963c0-17.546-5.518-33.794-14.888-47.142
		c-14.862-21.171-39.445-35.023-67.277-35.023h-4.906c-45.378,0-82.165,36.786-82.165,82.165v6.354v57.877h12.062V199.48
		c-0.002,0-0.005,7.451-0.007,7.451H324.377z"
        />
        <polygon fill="#FC6F58" points="181.3,206.931 181.307,206.931 181.307,199.48 	" />
      </g>
      <path
        fill="#F1543F"
        d="M323.593,122.049c-14.862-21.171-39.445-35.023-67.277-35.023H253.7v119.903h70.677v26.491h14.102
	v-43.268v-20.963C338.48,151.645,332.962,135.397,323.593,122.049z"
      />
      <circle fill="#59595B" cx="217.126" cy="224.521" r="7.794" />
      <circle fill="#272525" cx="290.599" cy="224.521" r="7.794" />
      <path
        fill="#1EA8A4"
        d="M325.154,323.558h-26.696c0,23.449-19.008,74.664-42.456,74.664s-42.456-51.215-42.456-74.664
	h-26.696c-32.153,0-58.218,26.065-58.218,58.218v96.337C166.14,499.671,209.63,512,256,512s89.86-12.329,127.372-33.887v-96.337
	C383.371,349.624,357.307,323.558,325.154,323.558z"
      />
      <path
        fill="#009687"
        d="M325.154,323.558h-26.696c0,23.449-19.008,74.664-42.456,74.664c-0.772,0-1.538-0.062-2.298-0.171
	v113.911c0.767,0.007,1.529,0.038,2.298,0.038c46.37,0,89.86-12.329,127.372-33.887v-96.337
	C383.371,349.624,357.307,323.558,325.154,323.558z"
      />
      <path
        fill="#FD8469"
        d="M253.862,288.39c-14.043,0-25.464-11.423-25.464-25.464c0-2.381,1.929-4.31,4.31-4.31
	s4.31,1.929,4.31,4.31c0,9.288,7.558,16.844,16.844,16.844c9.287,0,16.844-7.558,16.844-16.844c0-2.381,1.929-4.31,4.31-4.31
	s4.31,1.929,4.31,4.31C279.326,276.968,267.904,288.39,253.862,288.39z"
      />
      <path
        fill="#FC6F58"
        d="M275.016,258.617c-2.381,0-4.31,1.929-4.31,4.31c0,9.288-7.556,16.844-16.844,16.844
	c-0.055,0-0.107-0.007-0.162-0.009v8.62c0.055,0,0.107,0.009,0.162,0.009c14.041,0,25.464-11.423,25.464-25.464
	C279.326,260.546,277.397,258.617,275.016,258.617z"
      />
    </svg>
  </Box>
);

export { TeacherIllustration };
