import { FC } from 'react';
import { Box, SxProps, useTheme } from '@mui/material';

type SoccerIllustrationProps = {
  sx: SxProps;
};

const SoccerIllustration: FC<SoccerIllustrationProps> = ({ sx }) => {
  const theme = useTheme();
  const COLOR_PRIMARY = theme.palette.primary.main;

  return (
    <Box sx={sx}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 856.97376 510.42687"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>junior_soccer</title>
        <path
          d="M585.51312,699.85181c3.11869-3.93878,7.23183,3.896,11.346,1H574.06068C578.702,703.42853,582.324,695.6424,585.51312,699.85181Z"
          transform="translate(-171.51312 -194.78656)"
          fill="none"
        />
        <path
          d="M660.46959,700.85181h-3.93013c.84434.59319,1.69127,1.18363,2.49781,1.82471C659.49759,702.05434,659.98907,701.45755,660.46959,700.85181Z"
          transform="translate(-171.51312 -194.78656)"
          fill="none"
        />
        <path
          d="M555.81436,696.12481a37.62407,37.62407,0,0,0-18.29334,4.727h36.53966A37.4597,37.4597,0,0,0,555.81436,696.12481Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#e6e6e6"
        />
        <path
          d="M626.71943,691.3978a51.693,51.693,0,0,0-29.86028,9.454h59.68031A51.681,51.681,0,0,0,626.71943,691.3978Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#e6e6e6"
        />
        <path
          d="M1028.3635,631.5585c-2.66989-48.41162-41.56614-87.88292-89.93831-91.19138A96.92379,96.92379,0,0,0,838.23875,611.128c-1.174-.04212-2.34691-.08929-3.53112-.08929a96.926,96.926,0,0,0-92.97649,69.6,68.484,68.484,0,0,0-81.26155,20.21311H962.74242c37.13609,0,67.204-30.759,65.68966-67.86416Q1028.403,632.27416,1028.3635,631.5585Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#e6e6e6"
        />
        <rect y="505.06525" width="849" height="2" fill="#3f3d56" />
        <path
          d="M454.85706,375.02534s-38.53465-12.00334-32.61918,7.74526,38.373,9.77313,38.373,9.77313Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#ffb8b8"
        />
        <path
          d="M602.15945,300.9004s-19.74846-26.98668-38.93509,1.02891C544,330,526.12654,353.0444,526.12654,353.0444L478.38086,368.612l-27.62234,2.45078,7.60928,24.55807,80.31347-11.65108S628.09547,330.85575,602.15945,300.9004Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#2f2e41"
        />
        <path
          d="M514.01312,450.35181s-30,21-28,40,15,71,15,71l27-4s3-40-7-55l48-27Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#ffb8b8"
        />
        <path
          d="M580.51312,499.85181s-2,37,3,52,10,71,10,71h28s13-49-3-71c0,0-5-29,9-43Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#ffb8b8"
        />
        <path
          d="M574.01312,397.35181s-65,41-65,54l52,43,18-20-2,30,56,11s20-76,19-79-4-47-4-47Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#2f2e41"
        />
        <path
          d="M528.01312,552.35181l-29,6,5.15248,21.18242a75.64089,75.64089,0,0,1,.84752,31.81757h0s30,8,30,4-7-20-5-31S528.01312,552.35181,528.01312,552.35181Z"
          transform="translate(-171.51312 -194.78656)"
          fill={COLOR_PRIMARY}
        />
        <path
          d="M543.01312,627.35181c-1-4-9.30847-22.13574-9.65424-19.06787s-16.34576,8.06787-22.34576,2.06787c0,0-8-7-11,0s-9,19-9,19-27,13-23,20c.809,1.41578,3.05048,2.01288,6.1106,2.06543a1.63348,1.63348,0,0,0-.1106.5802v.70868a1.64571,1.64571,0,0,0,1.64569,1.64569h.70862a1.64571,1.64571,0,0,0,1.64569-1.64569v-.70868a1.62794,1.62794,0,0,0-.17975-.73346c.81787-.07165,1.66632-.16632,2.53864-.281a1.63022,1.63022,0,0,0-.35889,1.01446v.70868a1.64571,1.64571,0,0,0,1.64569,1.64569h.70862a1.64571,1.64571,0,0,0,1.64569-1.64569v-.70868a1.63844,1.63844,0,0,0-.85113-1.43267c2.30169-.38428,4.704-.87275,7.09265-1.41456a1.63059,1.63059,0,0,0-.24152.84723v.70868a1.64571,1.64571,0,0,0,1.64569,1.64569h.70862a1.64571,1.64571,0,0,0,1.64569-1.64569v-.70868a1.64389,1.64389,0,0,0-.97833-1.50177c1.79156-.4367,3.54754-.892,5.21881-1.34356a1.6305,1.6305,0,0,0-.24048.84533v.70868a1.64571,1.64571,0,0,0,1.64569,1.64569h.70862a1.64571,1.64571,0,0,0,1.64569-1.64569v-.70868a1.64,1.64,0,0,0-1.15723-1.56347c5.87268-1.6543,10.15723-3.08216,10.15723-3.08216s4.97553-1.81048,11-4.25628v.61059a1.64571,1.64571,0,0,0,1.64569,1.64569h.70862a1.64571,1.64571,0,0,0,1.64569-1.64569v-.70868a1.63608,1.63608,0,0,0-.63647-1.291c1.20953-.50842,2.42956-1.03119,3.63647-1.56287v.56256a1.64571,1.64571,0,0,0,1.64569,1.64569h.70862a1.64571,1.64571,0,0,0,1.64569-1.64569v-.70868a1.64158,1.64158,0,0,0-.70441-1.3471c1.305-.60865,2.56165-1.21686,3.72052-1.81256-.00518.05334-.01611.105-.01611.15966v.70868a1.64571,1.64571,0,0,0,1.64569,1.64569h.70862a1.64571,1.64571,0,0,0,1.64569-1.64569v-.70868a1.646,1.646,0,0,0-1.31695-1.61248C541.49225,629.76124,543.25537,628.32087,543.01312,627.35181Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#2f2e41"
        />
        <path
          d="M623.51312,618.35181l-31.68-2s1.92,33-1.92,44-1.92,15-1.92,15l27.84,2Z"
          transform="translate(-171.51312 -194.78656)"
          fill={COLOR_PRIMARY}
        />
        <path
          d="M623.9029,691.28633c-.144-4.12059-4.48845-23.5901-5.46628-20.66175s-17.6687,4.48236-22.28579-2.6368c0,0-6.36463-8.5142-10.75824-2.29357s-12.7638,16.70585-12.7638,16.70585-29.11715,7.08461-26.6646,14.76479c.496,1.55334,2.56373,2.60468,5.54563,3.29413a1.63368,1.63368,0,0,0-.22914.54438l-.14776.69311a1.6457,1.6457,0,0,0,1.26638,1.95265l.693.14775a1.64569,1.64569,0,0,0,1.95265-1.26638l.14777-.6931a1.628,1.628,0,0,0-.02287-.75482c.81484.10045,1.66438.18477,2.54143.25449a1.63027,1.63027,0,0,0-.56252.91734l-.14776.6931a1.64571,1.64571,0,0,0,1.26638,1.95266l.693.14775a1.64572,1.64572,0,0,0,1.95266-1.26638l.14776-.69311a1.63843,1.63843,0,0,0-.5337-1.57865c2.33123.10408,4.78256.12724,7.2317.0954a1.63059,1.63059,0,0,0-.41286.77825l-.14776.6931a1.64571,1.64571,0,0,0,1.26638,1.95266l.693.14775a1.64571,1.64571,0,0,0,1.95265-1.26639l.14777-.6931a1.64389,1.64389,0,0,0-.6437-1.67275c1.84324-.05356,3.65555-.13268,5.38424-.22588a1.63057,1.63057,0,0,0-.41145.77661l-.14776.69311a1.64571,1.64571,0,0,0,1.26638,1.95265l.693.14775a1.64569,1.64569,0,0,0,1.95265-1.26638l.14777-.6931a1.64008,1.64008,0,0,0-.8058-1.77041c6.08854-.39344,10.57663-.89656,10.57663-.89656s5.24366-.73327,11.64569-1.86918l-.12731.59718a1.64572,1.64572,0,0,0,1.26638,1.95266l.693.14775a1.64571,1.64571,0,0,0,1.95265-1.26639l.14777-.6931a1.63611,1.63611,0,0,0-.3533-1.39535c1.289-.245,2.59117-.50195,3.88241-.77029l-.11729.5502a1.64569,1.64569,0,0,0,1.26638,1.95265l.693.14776a1.64572,1.64572,0,0,0,1.95266-1.26639l.14776-.6931a1.64159,1.64159,0,0,0-.408-1.46437c1.40321-.32317,2.75906-.656,4.01668-.997-.0162.05109-.03765.09932-.04906.1528l-.14776.69311a1.64571,1.64571,0,0,0,1.26638,1.95265l.69305.14775a1.64571,1.64571,0,0,0,1.95266-1.26638l.14776-.6931a1.64606,1.64606,0,0,0-.9518-1.85164C621.91307,693.32569,623.93777,692.2846,623.9029,691.28633Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#2f2e41"
        />
        <circle cx="402.5" cy="43.56525" r="29" fill="#ffb8b8" />
        <path
          d="M600.01312,235.35181s16,20,24,19l-35,27s-4-17-9-19S600.01312,235.35181,600.01312,235.35181Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#ffb8b8"
        />
        <path
          d="M662.51312,383.85181S662.51069,273.50549,644,264c-18.5-9.5-30.73755-14.92211-30.73755-14.92211h0a25.95764,25.95764,0,0,1-9.98016,18.56709c-6.134,4.7357-12.24529,8.66252-15.65918,8.67509l-.11-.46827-.41644-1.18884-10.08356,15.68884s-9,2-12,8c-1.623,3.246,6.03052,35.9231,1.5,65.5-3.84375,25.09363-20.041,47.746-20.5,50.5-.77594,4.65546,18.91034-7.54187,27.77539-13.24408.13123.14637.22461.24408.22461.24408s-5,2,5-1c5.66608-1.69983,13.57715-1.79406,19.37207-1.55682-.007,1.73737.034,3.11774.12793,4.05682,1,10,51,24,58,21S662.51312,383.85181,662.51312,383.85181Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#2f2e41"
        />
        <path
          d="M627.51312,421.85181s-30,27-10,32,28-28,28-28Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#ffb8b8"
        />
        <path
          d="M592.51312,491.85181s14-13,22-6,13,12,13,12S604.51312,483.85181,592.51312,491.85181Z"
          transform="translate(-171.51312 -194.78656)"
          fill={COLOR_PRIMARY}
        />
        <path
          d="M596.14953,647.40022s7.89087-7.32724,12.39994-3.3818,7.32725,6.76361,7.32725,6.76361S602.91313,642.89115,596.14953,647.40022Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#2f2e41"
        />
        <path
          d="M507.74625,579.24559s5.41684-9.30656,10.879-6.84419,8.9785,4.33837,8.9785,4.33837S512.9048,572.96328,507.74625,579.24559Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#2f2e41"
        />
        <path
          d="M654.95682,277.72065c-9.91276-19.92594-40.07425-9.0584-35.17326,12.65073q.10959.48545.22956.98043a197.351,197.351,0,0,0,25,58l-11,49-12,25,25,6,31-75S669.46238,306.87871,654.95682,277.72065Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#2f2e41"
        />
        <polygon points="469 156.065 450 228.065 466 194.065 469 156.065" opacity="0.2" />
        <path
          d="M592.226,204.5479s-16.03027-16.87649-32.16549-6.24832S536.271,219.252,537.853,226.44057s6.26419,17.18755,6.26419,17.18755-2.90988-14.89267,10.4505-15.99474,31.81891-9.94376,31.81891-9.94376l12.48573,26.6639s1.76517-6.1789,6.65887-4.40663S606.63161,206.52462,592.226,204.5479Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#2f2e41"
        />
        <path
          d="M489.51312,585.85181a45.90581,45.90581,0,0,1-.63,7.56v.01a45.01143,45.01143,0,0,1-62.24,33.73h-.01a43.903,43.903,0,0,1-5.37-2.77,45.0673,45.0673,0,0,1-20.88-29.65,45.556,45.556,0,0,1-.87-8.88c0-1.36.06-2.71.18-4.04v-.00994a44.99843,44.99843,0,0,1,89.81,3.43v.01C489.51312,585.45179,489.51312,585.64179,489.51312,585.85181Z"
          transform="translate(-171.51312 -194.78656)"
          fill={COLOR_PRIMARY}
        />
        <path
          d="M455.59027,570.82154H435.436l-10.07763-17.45019,6.83544-11.84766.39942-.10742a46.155,46.155,0,0,1,26.30273.73535l.36231.12012,6.40967,11.09961Zm-19-2H454.436l8.92237-15.45019-5.44922-9.4375a44.35474,44.35474,0,0,0-24.39844-.69141l-5.84277,10.12891Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#fff"
        />
        <path
          d="M454.59027,606.82252H434.436l-10.07763-17.4541L434.436,571.91431h20.1543l10.07764,17.45411Zm-19-2H453.436l8.92237-15.4541L453.436,573.91431h-17.8457l-8.92236,15.45411Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#fff"
        />
        <path
          d="M444.51312,631.85181a45.58231,45.58231,0,0,1-18.26855-3.7832l-1.02784-.44629,10.21875-17.71094h20.15479l9.78808,16.95508-.97216.4668A45.57223,45.57223,0,0,1,444.51312,631.85181Zm-16.437-5.18359a44.04837,44.04837,0,0,0,34.48535-.68164l-8.12549-14.0752H436.59076Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#fff"
        />
        <path
          d="M488.59027,588.82154H468.436l-10.07763-17.45019,10.07714-17.46h9.17725l.2959.31153A45.7798,45.7798,0,0,1,490.50335,585.219v.29Zm-19-2H487.436l1.06348-1.84082a43.77854,43.77854,0,0,0-11.749-29.06934h-7.15967l-8.92285,15.46Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#fff"
        />
        <path
          d="M468.15814,625.33326l-9.7998-16.96191,10.07714-17.46h20.15381l1.29395,2.23047-.01416.44727a46.09583,46.09583,0,0,1-20.83155,31.18848Zm-7.49023-16.96191,8.1748,14.14843a44.09018,44.09018,0,0,0,18.99463-28.91894l-.40039-.68946H469.59076Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#fff"
        />
        <path
          d="M421.61908,625.7649l-.873-.52734A46.1146,46.1146,0,0,1,399.40277,594.928l-.07422-.37012,2.10742-3.64649h20.15479l10.07715,17.46Zm-20.17969-30.8623a44.11193,44.11193,0,0,0,19.47412,28.08593l8.44483-14.61718-8.92286-15.46H402.59027Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#fff"
        />
        <path
          d="M421.59027,586.82154H401.43646l-2.74316-4.74121v-.26856A45.86133,45.86133,0,0,1,413.18207,552.179l.28808-.26758h8.12061l10.07715,17.46Zm-19.00049-2H420.436l8.92237-15.45019-8.92286-15.46h-6.17627a43.8183,43.8183,0,0,0-13.5415,27.67383Z"
          transform="translate(-171.51312 -194.78656)"
          fill="#fff"
        />
      </svg>
    </Box>
  );
};

export { SoccerIllustration };
