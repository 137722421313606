import { FC } from 'react';
import { Box, SxProps } from '@mui/material';

type LegalGuardianIllustrationProps = {
  sx?: SxProps;
};

const LegalGuardianIllustration: FC<LegalGuardianIllustrationProps> = ({ sx }) => (
  <Box sx={sx}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <circle fill="#B5F1F4" cx="256" cy="256" r="256" />
      <path
        fill="#84DBFF"
        d="M358.741,490.529c75.981-33.333,132.66-102.495,148.668-186.116l-197.28-197.279l-91.957,111.039
	l-59.321,29.824l44.732,44.732l-4.472,3.015l51.428,51.428l-6.992,28.165L358.741,490.529z"
      />
      <path
        fill="#804000"
        d="M386.588,318.061h-33.616c-9.268,0-16.808-7.54-16.808-16.808v-33.616
	c0-9.268,7.54-16.808,16.808-16.808h33.616c9.268,0,16.808,7.54,16.808,16.808v33.616
	C403.396,310.52,395.854,318.061,386.588,318.061z M352.971,261.172c-3.563,0-6.465,2.901-6.465,6.465v33.616
	c0,3.563,2.901,6.465,6.465,6.465h33.616c3.563,0,6.465-2.901,6.465-6.465v-33.616c0-3.563-2.901-6.465-6.465-6.465H352.971z"
      />
      <path
        fill="#57442F"
        d="M386.588,250.828H369.78v10.343h16.808c3.563,0,6.465,2.901,6.465,6.465v33.616
	c0,3.563-2.901,6.465-6.465,6.465H369.78v10.343h16.808c9.268,0,16.808-7.54,16.808-16.808v-33.616
	C403.396,258.369,395.854,250.828,386.588,250.828z"
      />
      <path
        fill="#B97850"
        d="M439.022,398.222H300.534c-8.887,0-16.089-7.204-16.089-16.089v-97.689h170.667v97.689
	C455.113,391.018,447.909,398.222,439.022,398.222z"
      />
      <path
        fill="#935635"
        d="M369.78,284.444v113.778h69.244c8.887,0,16.089-7.204,16.089-16.089v-97.689H369.78z"
      />
      <path
        fill="#B97850"
        d="M284.446,284.444v97.689c0,8.887,7.204,16.089,16.089,16.089h69.244V284.444H284.446z"
      />
      <rect x="284.444" y="284.444" fill="#935635" width="170.667" height="56.889" />
      <rect x="369.778" y="284.444" fill="#804000" width="85.333" height="56.889" />
      <path
        fill="#FED8B2"
        d="M281.09,319.538v-27.822h-50.176v27.822c0,2.22-1.8,4.02-4.02,4.02h-34.576v96.011h127.371v-96.011
	h-34.576C282.889,323.558,281.09,321.758,281.09,319.538z"
      />
      <path
        fill="#EABE96"
        d="M319.686,323.558H285.11c-2.22,0-4.02-1.8-4.02-4.02v-27.822h-26.812v127.853h65.408V323.558z"
      />
      <circle fill="#FED8B2" cx="172.597" cy="233.417" r="20.052" />
      <circle fill="#EABE96" cx="335.127" cy="233.417" r="20.052" />
      <path
        fill="#F4E3C3"
        d="M256.122,316.926h-4.518c-41.796,0-75.678-33.882-75.678-75.678v-69.096
	c0-41.796,33.882-75.678,75.678-75.678h4.518c41.796,0,75.678,33.882,75.678,75.678v69.096
	C331.8,283.045,297.918,316.926,256.122,316.926z"
      />
      <path
        fill="#FED8B2"
        d="M256.122,96.475h-1.845v220.451h1.845c41.796,0,75.678-33.882,75.678-75.678v-69.096
	C331.8,130.357,297.918,96.475,256.122,96.475z"
      />
      <path
        fill="#324A5E"
        d="M323.593,122.051c-14.862-21.171-39.445-35.023-67.277-35.023h-4.906
	c-45.378,0-82.165,36.786-82.165,82.165v6.354v57.877h12.062v-33.942c-0.002,0-0.005,0-0.007,0
	c36.605-3.718,72.597-10.899,104.145-26.671c11.352,13.036,24.462,24.678,38.934,34.121v26.491h14.102v-43.268v-20.963
	C338.48,151.645,332.964,135.397,323.593,122.051z"
      />
      <path
        fill="#2B3B4E"
        d="M323.593,122.049c-14.862-21.171-39.445-35.023-67.277-35.023h-2.039v98.332
	c10.752-3.432,21.183-7.559,31.166-12.55c11.352,13.036,24.462,24.678,38.934,34.121v26.491h14.102v-43.268v-20.963
	C338.48,151.645,332.964,135.397,323.593,122.049z"
      />
      <circle fill="#59595B" cx="217.126" cy="224.521" r="7.794" />
      <circle fill="#272525" cx="290.599" cy="224.521" r="7.794" />
      <path
        fill="#FD8469"
        d="M253.862,288.39c-14.043,0-25.464-11.423-25.464-25.464c0-2.381,1.929-4.31,4.31-4.31
	s4.31,1.929,4.31,4.31c0,9.288,7.558,16.844,16.844,16.844c9.287,0,16.844-7.558,16.844-16.844c0-2.381,1.929-4.31,4.31-4.31
	s4.31,1.929,4.31,4.31C279.328,276.968,267.905,288.39,253.862,288.39z"
      />
      <path
        fill="#FC6F58"
        d="M279.328,262.927c0-2.381-1.929-4.31-4.31-4.31s-4.31,1.929-4.31,4.31
	c0,9.149-7.335,16.601-16.431,16.824v8.62C268.128,288.146,279.328,276.828,279.328,262.927z"
      />
      <path
        fill="#31BAFD"
        d="M213.544,323.558h-26.696c-32.153,0-58.218,26.065-58.218,58.218v95.075
	c36.957,21.718,79.844,34.404,125.649,35.083V365.971C231.629,365.066,213.544,346.429,213.544,323.558z"
      />
      <path
        fill="#2B9ED8"
        d="M325.154,323.558h-26.696c0,23.449-19.01,42.456-42.456,42.456c-0.578,0-1.152-0.021-1.724-0.043
	v145.963c1.288,0.019,2.569,0.066,3.86,0.066c45.496,0,88.211-11.883,125.235-32.692v-97.532
	C383.372,349.624,357.307,323.558,325.154,323.558z"
      />
      <path
        fill="#324A5E"
        d="M234.677,511.109C241.709,511.69,248.818,512,256,512c7.184,0,14.293-0.31,21.325-0.891
	L262.628,373.7h-13.255L234.677,511.109z"
      />
      <g>
        <path
          fill="#2B3B4E"
          d="M277.325,511.109L262.628,373.7h-8.351v138.281c0.574,0.003,1.146,0.019,1.722,0.019
		C263.184,512,270.293,511.69,277.325,511.109z"
        />
        <polygon
          fill="#2B3B4E"
          points="273.241,352.539 238.763,352.539 249.341,375.486 262.661,375.486 	"
        />
      </g>
      <polygon
        fill="#1C2A38"
        points="273.241,352.539 254.278,352.539 254.278,375.486 262.661,375.486 "
      />
      <polygon
        fill="#2B9ED8"
        points="227.557,323.558 256,352.539 227.557,380.983 199.113,323.558 "
      />
      <polygon
        fill="#208EBA"
        points="284.444,323.558 256.002,352.539 284.444,380.983 312.889,323.558 "
      />
    </svg>
  </Box>
);

export { LegalGuardianIllustration };
