import { Box, SxProps, useTheme } from '@mui/material';
import { FC } from 'react';

type LogicIllustrationProps = {
  sx?: SxProps;
};
const LogicIllustration: FC<LogicIllustrationProps> = ({ sx }) => {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Box sx={sx}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 732 603.79273"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect x="58.94488" y="354.47439" width="144.25984" height="134.95276" fill="#3f3d56" />
        <path
          d="M496.14961,684.454H234V455.65482H496.14961Zm-260.59843-1.55118H494.59843V457.206H235.55118Z"
          transform="translate(-234 -148.10364)"
          fill="#3f3d56"
        />
        <path
          d="M855.5748,368.954A110.4252,110.4252,0,1,1,966,258.52883,110.55,110.55,0,0,1,855.5748,368.954Zm0-219.21447A108.78927,108.78927,0,1,0,964.36407,258.52883,108.912,108.912,0,0,0,855.5748,149.73956Z"
          transform="translate(-234 -148.10364)"
          fill="#3f3d56"
        />
        <circle cx="618.30295" cy="110.42517" r="53.16769" fill="#3f3d56" />
        <rect
          x="916.00765"
          y="426.4219"
          width="36.46863"
          height="36.46866"
          transform="translate(-331.16342 378.58999) rotate(-30)"
          fill="#ff6584"
        />
        <rect
          x="434.00761"
          y="249.42191"
          width="36.46864"
          height="36.46866"
          transform="translate(-307.23919 113.87647) rotate(-30)"
          fill="#3f3d56"
        />
        <rect
          x="872.00756"
          y="625.42192"
          width="36.46863"
          height="36.46863"
          transform="translate(-436.55832 383.2509) rotate(-30)"
          fill="#e5e5e5"
        />
        <rect x="117.88976" y="408.76572" width="26.37008" height="26.37008" fill={PRIMARY_MAIN} />
        <circle cx="618.30295" cy="110.42517" r="13.08743" fill={PRIMARY_MAIN} />
        <rect
          x="754.12773"
          y="450.54208"
          width="78.22845"
          height="78.22842"
          transform="translate(-372.55387 314.11885) rotate(-30)"
          fill={PRIMARY_MAIN}
        />
        <rect x="179" y="601.79273" width="420" height="2" fill="#cbcbcb" />
        <path
          d="M572.38944,480.14641a13.55407,13.55407,0,0,1,4.3807-20.3166l-2.83038-30.84375,18.32415-6.29156L595.6492,466.308a13.62751,13.62751,0,0,1-23.25976,13.83842Z"
          transform="translate(-234 -148.10364)"
          fill="#ffb8b8"
        />
        <path
          d="M592.0611,303.12114s12.57481-26.38982,23.74853-17.10686-15.319,87.31213-15.319,87.31213l-4.02937,75.74867-21.23617-2.12261-6.0041-73.79994Z"
          transform="translate(-234 -148.10364)"
          fill="#ccc"
        />
        <polygon
          points="189.39 545.442 205.154 558.203 261.875 503.472 238.609 484.639 189.39 545.442"
          fill="#ffb8b8"
        />
        <path
          d="M423.53549,685.1442l31.04487,25.13019.00125.001a25.45513,25.45513,0,0,1,3.76811,35.799l-.52045.64289L407,705.57174Z"
          transform="translate(-234 -148.10364)"
          fill="#2f2e41"
        />
        <polygon
          points="408.471 580.865 428.753 580.864 438.401 502.636 408.468 502.637 408.471 580.865"
          fill="#ffb8b8"
        />
        <path
          d="M637.298,722.34686l39.94134-.00162h.00162a25.4551,25.4551,0,0,1,25.45374,25.45335v.82714l-65.39549.00243Z"
          transform="translate(-234 -148.10364)"
          fill="#2f2e41"
        />
        <path
          d="M678.0594,362.22742s38.04875,46.32022,29.77728,92.64044-14.88864,81.0604-14.88864,81.0604l-13.23435,104.2205s4.96288,28.38351-1.65429,35.69757c0,0,9.92576,17.23983-8.27147,23.857s-28.0158-2.28605-28.0158-2.28605-13.34154-19.21976-5.07008-24.18265l3.48444-204.53023L507.66714,641.80305s-2.43585,21.0559-19.41515,21.28086L467.9641,678.19752,444.804,664.96317,456.384,650.07452s-6.61718-16.54293,8.27147-19.85152l86.02327-112.492,21.50582-57.90028s11.58006-44.66594,43.01164-62.86317v-19.363Z"
          transform="translate(-234 -148.10364)"
          fill="#2f2e41"
        />
        <path
          d="M612.7148,281.99417l0,0a31.58927,31.58927,0,0,1,41.63739-5.879l1.37423.91616,0,0A121.9371,121.9371,0,0,1,684.2712,335.802l6.1954,33.86975-77.7518,16.84253-18.22756-62.86354a35.51549,35.51549,0,0,1,18.22753-41.65654Z"
          transform="translate(-234 -148.10364)"
          fill="#ccc"
        />
        <path
          d="M740.45624,487.34748a15.51228,15.51228,0,0,1-8.84913-22.07886L709.178,437.81848l13.357-17.69865,31.12978,39.20977a15.59633,15.59633,0,0,1-13.20857,28.01788Z"
          transform="translate(-234 -148.10364)"
          fill="#ffb8b8"
        />
        <path
          d="M645.80067,306.80858s-4.96288-33.08588,11.58006-31.43158,41.35734,92.64044,41.35734,92.64044L743.404,442.46066l-21.50582,11.58006L668.96078,387.869Z"
          transform="translate(-234 -148.10364)"
          fill="#ccc"
        />
        <path
          d="M676.09457,258.43842H597.31821a6.11886,6.11886,0,0,1-6.112-6.112V218.37113a45.50014,45.50014,0,1,1,91.00028,0v33.95533A6.11886,6.11886,0,0,1,676.09457,258.43842Z"
          transform="translate(-234 -148.10364)"
          fill="#2f2e41"
        />
        <circle
          cx="646.0562"
          cy="224.28886"
          r="33.3591"
          transform="translate(-94.63419 535.48792) rotate(-61.33682)"
          fill="#ffb8b8"
        />
        <path
          d="M692.97077,221.76666H650.9889l-.43061-6.91574-2.15245,6.91574h-6.46431l-.85312-13.70681-4.26619,13.70681H624.3141v-.6791c0-19.84663,14.07189-35.99265,31.3687-35.99265h5.919c17.29682,0,31.369,16.146,31.369,35.99265Z"
          transform="translate(-234 -148.10364)"
          fill="#2f2e41"
        />
        <path
          d="M652.72634,264.77686a10.10052,10.10052,0,0,1-1.53237-.1181l-49.96949-7.69655v-52.0466a20.047,20.047,0,0,1,20.047-20.047h34.95978l-1.36185,1.3861c-18.94755,19.29058-4.67277,50.57051,5.52257,67.499a6.67141,6.67141,0,0,1-.67788,7.90652A9.07762,9.07762,0,0,1,652.72634,264.77686Z"
          transform="translate(-234 -148.10364)"
          fill="#2f2e41"
        />
      </svg>
    </Box>
  );
};

export { LogicIllustration };
