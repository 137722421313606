import { FC } from 'react';
import { Box, SxProps } from '@mui/material';

type GalleryIllustrationProps = {
  sx?: SxProps;
};

const GalleryIllustration: FC<GalleryIllustrationProps> = ({ sx }) => (
  <Box sx={sx}>
    <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
      <g id="SVGRepo_iconCarrier">
        <path
          d="M24,22V15.515l-1.071-1.071a6,6,0,0,0-8.485,0h0a6,6,0,0,0,0,8.485L20.1,28.586a6,6,0,0,0,8.485,0h0a6.142,6.142,0,0,0,.537-.657A6,6,0,0,1,24,22Z"
          fill="#ffe1a0"
        />
        <path
          d="M24.343,31.839a7.475,7.475,0,0,1-5.3-2.193l-5.657-5.657A7.5,7.5,0,0,1,23.989,13.383l1.072,1.071a1.5,1.5,0,0,1,.439,1.061V22a4.519,4.519,0,0,0,3.841,4.445,1.5,1.5,0,0,1,.981,2.386,7.285,7.285,0,0,1-.675.815A7.479,7.479,0,0,1,24.343,31.839ZM18.687,14.188a4.5,4.5,0,0,0-3.183,7.68l5.657,5.657a4.512,4.512,0,0,0,5.029.922A7.54,7.54,0,0,1,22.5,22V16.136l-.632-.632A4.485,4.485,0,0,0,18.687,14.188Z"
          fill="#f29580"
        />
        <path
          d="M45.556,37.071,39.9,31.414a6,6,0,0,0-8.485,0h0a6.142,6.142,0,0,0-.537.657A6,6,0,0,1,36,38v6.485l1.071,1.071a6,6,0,0,0,8.485-8.485Z"
          fill="#c1f7fd"
        />
        <path
          d="M41.313,48.813a7.451,7.451,0,0,1-5.3-2.2l-1.072-1.071a1.5,1.5,0,0,1-.439-1.061V38a4.519,4.519,0,0,0-3.841-4.445,1.5,1.5,0,0,1-.981-2.386,7.285,7.285,0,0,1,.675-.815,7.509,7.509,0,0,1,10.607,0l5.657,5.657a7.5,7.5,0,0,1-5.3,12.8ZM37.5,43.864l.632.631A4.5,4.5,0,1,0,44.5,38.132l-5.657-5.657a4.507,4.507,0,0,0-5.029-.922A7.54,7.54,0,0,1,37.5,38Z"
          fill="#7bcdd1"
        />
        <path
          d="M28.586,31.414h0a6.142,6.142,0,0,0-.657-.537A6,6,0,0,1,22,36H15.515l-1.071,1.071a6,6,0,0,0,8.485,8.485L28.586,39.9A6,6,0,0,0,28.586,31.414Z"
          fill="#f2bdd6"
        />
        <path
          d="M18.687,48.813a7.5,7.5,0,0,1-5.3-12.8l1.071-1.072a1.5,1.5,0,0,1,1.061-.439H22a4.517,4.517,0,0,0,4.444-3.841,1.5,1.5,0,0,1,2.386-.98,7.193,7.193,0,0,1,.815.672v0a7.508,7.508,0,0,1,0,10.606l-5.657,5.657A7.451,7.451,0,0,1,18.687,48.813ZM16.136,37.5l-.632.632A4.5,4.5,0,0,0,21.868,44.5l5.657-5.657a4.509,4.509,0,0,0,.922-5.029A7.54,7.54,0,0,1,22,37.5Z"
          fill="#bf95bc"
        />
        <path
          d="M38,24h6.485l1.071-1.071a6,6,0,0,0,0-8.485h0a6,6,0,0,0-8.485,0L31.414,20.1a6,6,0,0,0,0,8.486h0a6.013,6.013,0,0,0,.657.536A6,6,0,0,1,38,24Z"
          fill="#f1f3f4"
        />
        <path
          d="M32.071,30.623a1.5,1.5,0,0,1-.9-.3,7.193,7.193,0,0,1-.815-.672,7.511,7.511,0,0,1,0-10.609l5.657-5.657A7.5,7.5,0,1,1,46.617,23.989l-1.071,1.072a1.5,1.5,0,0,1-1.061.439H38a4.517,4.517,0,0,0-4.444,3.841,1.5,1.5,0,0,1-1.485,1.282Zm9.242-16.435A4.485,4.485,0,0,0,38.132,15.5l-5.657,5.657a4.509,4.509,0,0,0-.922,5.029A7.54,7.54,0,0,1,38,22.5h5.864l.632-.632a4.5,4.5,0,0,0-3.183-7.68Z"
          fill="#f2bf80"
        />
        <path
          d="M31.414,20.1,36,15.515V14a6,6,0,0,0-12,0v8a5.959,5.959,0,0,0,6.877,5.929A5.985,5.985,0,0,1,31.414,20.1Z"
          fill="#eff28d"
        />
        <path
          d="M30,29.5A7.508,7.508,0,0,1,22.5,22V14a7.5,7.5,0,0,1,15,0v1.515a1.5,1.5,0,0,1-.439,1.06l-4.586,4.586a4.508,4.508,0,0,0-.4,5.867,1.5,1.5,0,0,1-.982,2.385A7.485,7.485,0,0,1,30,29.5Zm0-20A4.505,4.505,0,0,0,25.5,14v8a4.507,4.507,0,0,0,2.921,4.214,7.506,7.506,0,0,1,1.933-7.174L34.5,14.894V14A4.505,4.505,0,0,0,30,9.5Z"
          fill="#f2bf80"
        />
        <path
          d="M30,32a6.033,6.033,0,0,0-.877.071,5.983,5.983,0,0,1-.537,7.828L24,44.485V46a6,6,0,0,0,12,0V38A6,6,0,0,0,30,32Z"
          fill="#bec6f4"
        />
        <path
          d="M30,53.5A7.508,7.508,0,0,1,22.5,46V44.485a1.5,1.5,0,0,1,.439-1.06l4.586-4.586a4.508,4.508,0,0,0,.4-5.867,1.5,1.5,0,0,1,.982-2.385A7.458,7.458,0,0,1,37.5,38v8A7.508,7.508,0,0,1,30,53.5Zm-4.5-8.394V46a4.5,4.5,0,0,0,9,0V38a4.507,4.507,0,0,0-2.921-4.214,7.506,7.506,0,0,1-1.933,7.174Z"
          fill="#8d9cf4"
        />
        <path
          d="M20.1,28.586,15.515,24H14a6,6,0,0,0,0,12h8a5.96,5.96,0,0,0,5.929-6.878A5.982,5.982,0,0,1,20.1,28.586Z"
          fill="#ffafc5"
        />
        <path
          d="M22,37.5H14a7.5,7.5,0,0,1,0-15h1.515a1.5,1.5,0,0,1,1.06.439l4.586,4.586a4.508,4.508,0,0,0,5.867.4,1.5,1.5,0,0,1,2.385.982A7.372,7.372,0,0,1,29.5,30,7.508,7.508,0,0,1,22,37.5Zm-8-12a4.5,4.5,0,0,0,0,9h8a4.507,4.507,0,0,0,4.214-2.921,7.506,7.506,0,0,1-7.174-1.933L14.894,25.5Z"
          fill="#f28080"
        />
        <path
          d="M46,24H38a5.959,5.959,0,0,0-5.929,6.877,5.983,5.983,0,0,1,7.828.537L44.485,36H46a6,6,0,0,0,0-12Z"
          fill="#c1f7fd"
        />
        <path
          d="M46,37.5H44.485a1.5,1.5,0,0,1-1.06-.439l-4.586-4.586a4.5,4.5,0,0,0-5.866-.4,1.5,1.5,0,0,1-2.386-.982A7.372,7.372,0,0,1,30.5,30,7.508,7.508,0,0,1,38,22.5h8a7.5,7.5,0,0,1,0,15Zm-.894-3H46a4.5,4.5,0,0,0,0-9H38a4.507,4.507,0,0,0-4.214,2.921,7.511,7.511,0,0,1,7.174,1.933Z"
          fill="#bbd9c8"
        />
        <path
          d="M24,23.5A1.5,1.5,0,0,1,22.5,22V14A7.508,7.508,0,0,1,30,6.5a1.5,1.5,0,0,1,0,3A4.505,4.505,0,0,0,25.5,14v8A1.5,1.5,0,0,1,24,23.5Z"
          fill="#f2c4bb"
        />
      </g>
    </svg>
  </Box>
);

export { GalleryIllustration };
