import { FC } from 'react';
import { Box, SxProps, useTheme } from '@mui/material';

type SuperWomanIllustrationProps = {
  sx?: SxProps;
};

const SuperWomanIllustration: FC<SuperWomanIllustrationProps> = ({ sx }) => {
  const theme = useTheme();
  const COLOR_PRIMARY = theme.palette.primary.main;

  return (
    <Box sx={sx}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 860.28222 771.92428"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>super woman</title>
        <ellipse cx="449.78288" cy="728.92428" rx="283" ry="43" fill="#f2f2f2" />
        <path
          d="M797.69781,640.497a20.81252,20.81252,0,1,0,2.7716-39.91523l.52093,10.7122-5.06814-9.18046a20.734,20.734,0,0,0-10.68367,11.72261,20.40854,20.40854,0,0,0-1.19713,5.62987A20.80853,20.80853,0,0,0,797.69781,640.497Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#57b894"
        />
        <path
          d="M822.77515,764.74043c-1.78905-9.11027,5.96331-17.1868,13.62087-22.43652s16.605-10.40779,19.21775-19.31684c3.755-12.80387-7.43-24.5298-16.13564-34.64175a125.30021,125.30021,0,0,1-16.52359-24.55739c-1.81108-3.5325-3.47558-7.22527-3.95222-11.16626-.6864-5.67546,1.13694-11.32308,2.97391-16.73673q9.17924-27.05168,19.62843-53.65005"
          transform="translate(-170.10856 -63.78786)"
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
        />
        <path
          d="M798.55493,637.73906a20.81252,20.81252,0,1,0,2.7716-39.91523l.52093,10.7122-5.06815-9.18045a20.734,20.734,0,0,0-10.68367,11.7226,20.40888,20.40888,0,0,0-1.19712,5.62987A20.80853,20.80853,0,0,0,798.55493,637.73906Z"
          transform="translate(-170.10856 -63.78786)"
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
        />
        <path
          d="M820.23135,578.53552a20.78824,20.78824,0,0,1,14.97993-13.19763l1.71362,10.18378,3.177-10.69567a20.81,20.81,0,1,1-19.87058,13.70952Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#57b894"
        />
        <path
          d="M821.37418,574.85827a20.78818,20.78818,0,0,1,14.97993-13.19763l1.71361,10.18378,3.177-10.69567a20.81,20.81,0,1,1-19.87057,13.70952Z"
          transform="translate(-170.10856 -63.78786)"
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
        />
        <path
          d="M835.82474,688.55172a20.81,20.81,0,0,0,18.419-37.02268l-2.44121,8.21926-1.73106-10.30382a.36335.36335,0,0,0-.053-.02009,20.81113,20.81113,0,1,0-14.1938,39.12733Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#57b894"
        />
        <path
          d="M836.815,684.827a20.81,20.81,0,0,0,18.419-37.02268l-2.44121,8.21926-1.731-10.30382a.36307.36307,0,0,0-.053-.02009A20.81113,20.81113,0,1,0,836.815,684.827Z"
          transform="translate(-170.10856 -63.78786)"
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
        />
        <path
          d="M814.15782,723.11434A20.80131,20.80131,0,1,0,818.1684,706.817l9.27267,13.95654-12.66994-7.40769A20.61627,20.61627,0,0,0,814.15782,723.11434Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#57b894"
        />
        <path
          d="M815.01494,720.35641a20.80131,20.80131,0,1,0,4.01058-16.29737l9.27266,13.95654-12.66994-7.40769A20.61642,20.61642,0,0,0,815.01494,720.35641Z"
          transform="translate(-170.10856 -63.78786)"
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
        />
        <path
          d="M756.86664,177.62556a58.487,58.487,0,0,1,58.40593-55.43027c1.77754,0,3.5328.09271,5.26995.248a77.70681,77.70681,0,0,1,134.32685-.06351c1.46553-.11007,2.94093-.18445,4.43459-.18445a58.487,58.487,0,0,1,58.40593,55.43027"
          transform="translate(-170.10856 -63.78786)"
          fill="#f2f2f2"
        />
        <path
          d="M769.04819,173.12649a58.487,58.487,0,0,1,58.40593-55.43026c1.77754,0,3.5328.0927,5.26995.248a77.70681,77.70681,0,0,1,134.32685-.06351c1.46553-.11007,2.94092-.18445,4.43459-.18445a58.487,58.487,0,0,1,58.40593,55.43026"
          transform="translate(-170.10856 -63.78786)"
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
        />
        <path
          d="M331.86664,455.62556a58.487,58.487,0,0,1,58.40593-55.43027c1.77754,0,3.5328.09271,5.27.248a77.70681,77.70681,0,0,1,134.32685-.06351c1.46553-.11007,2.94093-.18445,4.43459-.18445a58.487,58.487,0,0,1,58.40593,55.43027"
          transform="translate(-170.10856 -63.78786)"
          fill="#f2f2f2"
        />
        <path
          d="M344.04819,451.12649a58.487,58.487,0,0,1,58.40593-55.43026c1.77754,0,3.5328.0927,5.26995.248a77.70681,77.70681,0,0,1,134.32685-.06351c1.46553-.11007,2.94092-.18445,4.43459-.18445a58.487,58.487,0,0,1,58.40593,55.43026"
          transform="translate(-170.10856 -63.78786)"
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
        />
        <path
          d="M207.86664,162.62556a58.487,58.487,0,0,1,58.40593-55.43027c1.77754,0,3.5328.09271,5.27.248a77.70681,77.70681,0,0,1,134.32685-.06351c1.46553-.11007,2.94093-.18445,4.43459-.18445a58.487,58.487,0,0,1,58.40593,55.43027"
          transform="translate(-170.10856 -63.78786)"
          fill="#f2f2f2"
        />
        <path
          d="M220.04819,158.12649a58.487,58.487,0,0,1,58.40593-55.43026c1.77754,0,3.5328.0927,5.26995.248a77.70681,77.70681,0,0,1,134.32685-.06351c1.46553-.11007,2.94092-.18445,4.43459-.18445a58.487,58.487,0,0,1,58.40593,55.43026"
          transform="translate(-170.10856 -63.78786)"
          fill="none"
          stroke="#3f3d56"
          strokeMiterlimit="10"
        />
        <path
          d="M465.161,281.26091s105.12,52.19753,123.96913,44.94787,25.3738-71.7716,25.3738-71.7716l-77.57133-14.49931Z"
          transform="translate(-170.10856 -63.78786)"
          fill={COLOR_PRIMARY}
        />
        <path
          d="M465.161,281.26091s105.12,52.19753,123.96913,44.94787,25.3738-71.7716,25.3738-71.7716l-77.57133-14.49931Z"
          transform="translate(-170.10856 -63.78786)"
          opacity="0.1"
        />
        <rect x="444.03289" y="139.53925" width="68.87174" height="60.17215" fill="#ff6584" />
        <rect x="444.03289" y="139.53925" width="68.87174" height="60.17215" opacity="0.1" />
        <path
          d="M551.43193,459.60246s-2.89987,63.072,16.67421,61.62208,0-59.44718,0-59.44718Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#fbbebe"
        />
        <path
          d="M631.90311,212.38917s4.3498,27.5487-3.62483,31.17353-13.04938,6.52469-13.04938,6.52469L633.353,262.4118l33.34842,2.1749,8.69959-6.52469,7.97462-7.24966s-13.04938-2.89986-13.04938-9.42455-.725-26.09876-.725-26.09876Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#fbbebe"
        />
        <path
          d="M631.90311,212.38917s4.3498,27.5487-3.62483,31.17353-13.04938,6.52469-13.04938,6.52469L633.353,262.4118l33.34842,2.1749,8.69959-6.52469,7.97462-7.24966s-13.04938-2.89986-13.04938-9.42455-.725-26.09876-.725-26.09876Z"
          transform="translate(-170.10856 -63.78786)"
          opacity="0.1"
        />
        <path
          d="M586.23028,470.477s-21.024,67.4218-21.024,116.71947c0,0-34.79835,78.29629-33.34842,116.71947l-8.69958,52.92249,12.32441,4.34979,9.42455-7.97462s2.89987-28.27366,12.32442-42.048,31.17352-79.02125,31.17352-79.02125,57.99726-121.79423,55.82236-139.19341S586.23028,470.477,586.23028,470.477Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#fbbebe"
        />
        <path
          d="M714.5492,455.25267s13.77434,6.52469-5.79973,122.51919c0,0,10.87449,76.84636,1.44993,118.89437l-1.44993,42.048H693.52519l-2.89986-4.3498s-.725-37.69821-5.79972-47.84773-9.42456-68.87174-9.42456-68.87174-23.1989-119.61933-18.12414-129.76885S714.5492,455.25267,714.5492,455.25267Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#fbbebe"
        />
        <path
          d="M520.98337,744.514s5.07476,11.59945,14.49931,8.69959,11.59945-2.89986,11.59945-2.89986-6.52469,33.34842-4.34979,49.29766-1.44993,26.09877-15.94925,26.09877-16.67421-17.39918-16.67421-17.39918l6.52469-41.323Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#2f2e41"
        />
        <path
          d="M687.72547,726.38982s5.07476,10.14952,13.04938,7.97462,10.87448-5.79972,10.87448-5.79972-.725,22.47393,2.1749,32.62345,9.42456,39.14815-5.79972,40.59808a19.57344,19.57344,0,0,1-20.299-11.59945v-63.797Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#2f2e41"
        />
        <circle cx="481.36863" cy="135.55194" r="31.89849" fill="#fbbebe" />
        <path
          d="M710.1994,256.61208s-25.26734-9.92145-34.38264-8.58555a37.25942,37.25942,0,0,1-28.68937,8.58555c-17.39917-2.1749-24.2029-10.52478-24.2029-10.52478s-46.84373,10.52478-43.94387,14.87457,15.94924,75.39643,15.94924,75.39643,8.69959,61.62208-1.44993,82.64609-15.22428,55.82235-10.87448,58.72221,52.92249,15.94925,58.72222,19.57408,7.97462-8.69959,11.59945-6.52469,11.59945,5.79972,16.67421,2.89986,54.37242-28.99863,52.92249-34.07339-20.299-66.69684-26.82373-79.02125-1.44993-38.42318,2.1749-43.49794,10.87448-10.87449,12.32441-25.3738S710.1994,256.61208,710.1994,256.61208Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#2f2e41"
        />
        <path
          d="M580.57841,258.72133s-5.94758,4.41544-8.12248,13.84-5.07476,84.821-5.07476,84.821-26.82373,103.67008-21.024,105.845,22.47393,6.52469,24.64883,4.34979,26.82373-89.89574,24.64883-99.32029,2.89986-36.97325,2.89986-36.97325Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#2f2e41"
        />
        <path
          d="M714.5492,393.63059s-39.14815-19.57407-43.49794-6.52469,41.323,26.82373,41.323,26.82373,15.94924-5.79973,15.94924-9.42456S714.5492,393.63059,714.5492,393.63059Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#fbbebe"
        />
        <path
          d="M702.22478,259.51194l7.97462-2.89986s4.3498-2.89986,9.42456,7.97462,60.17215,74.67146,50.02263,94.9705-43.49794,61.62208-44.94787,58.72222-18.12414-24.64883-15.22428-26.82373,36.24828-31.89849,31.89849-36.24828-22.47394-41.323-32.62346-42.773S702.22478,259.51194,702.22478,259.51194Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#2f2e41"
        />
        <path
          d="M616.31635,198.25234s-110.54869-44.44993-206.24416-87.2229c0,0-50.02263-12.32442-76.12139,10.87448,0,0,21.749-2.89986,21.749,9.42456,0,0,18.8491,24.64883-39.87311,28.99862-40.89583,3.02933-57.17843,27.50732-63.29552,42.08979A59.24487,59.24487,0,0,1,228.57346,230.587c-14.13464,8.44768-34.62017,14.66271-58.4649,3.68656,0,0,1.35559,2.084,3.92695,5.55645,46.0383,62.17216,217.481,116.28076,278.02235,68.11808,37.41476-29.7647,96.72794-67.385,139.24718-48.43619,66.69684,29.72359,79.02125,16.67421,79.02125,16.67421l2.89987-7.97462s-64.522-17.39918-65.97188-39.87311S616.31635,198.25234,616.31635,198.25234Z"
          transform="translate(-170.10856 -63.78786)"
          fill={COLOR_PRIMARY}
        />
        <path
          d="M680.83829,204.05207s15.58677,6.16221,18.48663,26.46125c0,0,16.67421,32.62345-2.1749,40.59807s-22.47393,5.79973-22.47393,5.79973v-3.62483s20.299-12.32442,15.94924-21.749S680.83829,204.05207,680.83829,204.05207Z"
          transform="translate(-170.10856 -63.78786)"
          fill={COLOR_PRIMARY}
        />
        <path
          d="M652.92712,296.48519s5.07476-13.04938,3.62483-13.04938,5.89836-8.026,8.7489-5.10045,7.92531,11.62514,2.12558,13.8S652.92712,296.48519,652.92712,296.48519Z"
          transform="translate(-170.10856 -63.78786)"
          fill={COLOR_PRIMARY}
        />
        <path
          d="M688.656,288.73956s-13.25223-4.51862-13.19094-3.07-8.26815-5.55384-5.46571-8.5255,11.27974-8.40961,13.69785-2.707S688.656,288.73956,688.656,288.73956Z"
          transform="translate(-170.10856 -63.78786)"
          fill={COLOR_PRIMARY}
        />
        <path
          d="M667.42643,268.9365s6.61905-3.71513,10.55918,2.12974-4.75945,15.9944-10.55918,15.9944-5.79973-12.32442-5.79973-12.32442Z"
          transform="translate(-170.10856 -63.78786)"
          fill={COLOR_PRIMARY}
        />
        <path
          d="M667.42643,268.9365s6.61905-3.71513,10.55918,2.12974-4.75945,15.9944-10.55918,15.9944-5.79973-12.32442-5.79973-12.32442Z"
          transform="translate(-170.10856 -63.78786)"
          opacity="0.1"
        />
        <path
          d="M623.82107,147.28577c-3.82238,3.15091-7.44723,6.682-9.82414,11.0281a49.47886,49.47886,0,0,0-3.11533,7.58425c-2.93163,8.43717-10.21915,16.98234-7.13481,25.36489.88011,2.39193,2.25238,4.91391,1.46341,7.33743-.70069,2.15231-2.86338,3.40831-4.42762,5.04432-3.35738,3.51142-3.92177,8.78008-4.12686,13.634-.3756,8.8891,4.51213,19.36439,10.418,26.01854a36.35608,36.35608,0,0,1,3.55418,4.0236,9.43178,9.43178,0,0,1,1.1133,7.75883l8.60341-1.79561a71.36278,71.36278,0,0,1,.29032,9.55906,8.92381,8.92381,0,0,0,9.46276-6.11555,25.551,25.551,0,0,0,2.36354,6.53063,33.70927,33.70927,0,0,0,5.71418-10.58251,30.25635,30.25635,0,0,0,1.02617-15.2781,64.78435,64.78435,0,0,0-3.70426-10.85941c-3.04-7.68275-5.52249-15.57226-8.00218-23.4537-1.28788-4.09341-2.59131-8.31991-2.201-12.59335l3.057-.3325a14.59645,14.59645,0,0,1,.96489-4.93366,18.10138,18.10138,0,0,1,5.11254,1.92416l3.52412-8.94225c2.3259,2.41217,5.66385,3.504,8.86,4.51037l12.14847,3.82516.07395-2.40425a62.35343,62.35343,0,0,0,17.28717,10.84362,3.93274,3.93274,0,0,1-.85375-3.12932c2.99375,5.36313,1.79654,12.04445-.05106,17.9021s-4.29883,11.82112-3.55478,17.918c.55648,4.55993,2.89237,8.92087,2.45932,13.49417-.21326,2.25209-1.09408,4.39517-1.368,6.64069-.65327,5.35552,2.16255,10.45542,4.88066,15.11592l5.28537-7.25854c1.93437-2.65651,3.92822-5.37093,6.66714-7.18672,2.81156-1.864,6.2965-2.70073,8.74663-5.01936,4.37384-4.1391,3.81852-11.19066,2.39615-17.04212-.75612-3.11056-1.67107-6.26525-1.39309-9.4543.47124-5.40617,4.22787-9.87921,6.34364-14.87644a25.82573,25.82573,0,0,0-1.44726-22.70338c-2.62532-4.50312-6.59227-8.12979-9.06629-12.71777-2.46279-4.56715-3.40224-10.0014-6.77676-13.94308a23.52175,23.52175,0,0,0-5.6807-4.526,74.05661,74.05661,0,0,0-31.5064-10.27882C640.835,136.812,631.91946,140.61,623.82107,147.28577Z"
          transform="translate(-170.10856 -63.78786)"
          fill="#ff6584"
        />
      </svg>
    </Box>
  );
};

export { SuperWomanIllustration };
